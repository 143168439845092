import { Button, message } from 'antd';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import styles from './index.module.css';
import { useDeepGuard } from '../../index';
import Prompt from '../../assets/prompt.url.svg';
import to from '../../utils/await-to';
import styleCommon from '../style/index.module.css';
import { useNavigate } from 'react-router-dom';

const Destroy = () => {
  const deepGuard = useDeepGuard();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(5);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearTimeout(timer); // 清除定时器
    }
  });

  const handleVerifyMoble = async () => {
    const [err] = await to(deepGuard.destroy());
    if (err) {
      message.error('注销失败');
      return;
    }
    message.success('已注销');
    deepGuard.hide();

    setTimeout(() => {
      navigate('/login');
    }, 1000);
  };

  return (
    <div className={styles.cancellateConfirm}>
      <div className={styles.destroyContent}>
        <div className={styles.promptImg}>
          <img src={Prompt} alt="" />
        </div>
        <div className={cx(styles.confirmTips)}>
          <div className={cx(styleCommon.fs16, styleCommon.bold)}>注销账号</div>
          <div className={cx(styleCommon.fs14, styleCommon.mt8)}>
            注销账号后，你的全部数据都将遗失，请谨慎操作。
          </div>
        </div>
      </div>
      <div className={cx(styles.confirmOptions, styleCommon.formItemContent)}>
        <Button
          className={isNaN(countdown) || countdown > 0 ? 'disabledCacellateBtn' : 'antBtnDefault'}
          disabled={isNaN(countdown) || countdown > 0}
          onClick={handleVerifyMoble}
        >
          {isNaN(countdown) || countdown > 0 ? `我已了解 ${countdown || 5}s` : '注销'}
        </Button>
        <Button
          type="primary"
          className={styleCommon.ml8}
          onClick={() => {
            deepGuard.hide();
          }}
        >
          取消
        </Button>
      </div>
    </div>
  );
};

export default Destroy;
