import {
  IconAlertTriangle,
  IconCheck,
  IconCopy,
  // IconEdit,
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
  // IconTrash,
  IconX,
} from '@tabler/icons-react';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';

import { updateConversation } from '../../utils/app/conversation';

import { ConversationItem } from '../../types/chat';

import HomeContext from '../../pages/home/home.context';

import { CodeBlock } from '../Markdown/CodeBlock';
import { MemoizedReactMarkdown } from '../Markdown/MemoizedReactMarkdown';

import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { reqFeedbackMessage, reqLikeMessage } from '../../api';

import DeepChat from '../../../assets/images/DeepLang.jpg';
import User from '../../../assets/images/user.png';

import toast, { Toaster } from 'react-hot-toast';
import '../../styles/message.css';

export interface Props {
  message: ConversationItem;
  messageIndex: number;
  onEdit?: (editedMessage: ConversationItem) => void;
}

export const ChatMessage: FC<Props> = memo(({ message, messageIndex, onEdit }) => {
  const {
    state: { selectedConversation, conversations, messageIsStreaming, lightMode },
    dispatch: homeDispatch,
  } = useContext(HomeContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState(message.chatContent);
  const [messagedCopied, setMessageCopied] = useState(false);
  const [thumbUp, setThumbUp] = useState<boolean>(false); // 点赞点踩
  const [thumbDown, setThumbDown] = useState<boolean>(false);
  const [showFeekback, setShowFeedback] = useState<boolean>(false);
  const [feedbackContent, setFeedbackContent] = useState<string>('');
  const [isUnSafe, setIsUnSafe] = useState<boolean>(false);
  const [isUseless, setIsUseless] = useState<boolean>(false);
  const [isNotTrue, setIsNotTrue] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageContent(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleEditMessage = () => {
    if (message.chatContent != messageContent) {
      if (selectedConversation && onEdit) {
        onEdit({ ...message, chatContent: messageContent });
      }
    }
    setIsEditing(false);
  };

  const handleDeleteMessage = () => {
    if (!selectedConversation) return;

    const { chatList } = selectedConversation;
    const findIndex = chatList.findIndex((elm: ConversationItem) => elm === message);

    if (findIndex < 0) return;

    if (findIndex < chatList.length - 1 && chatList[findIndex + 1].chatRole === 'assistant') {
      chatList.splice(findIndex, 2);
    } else {
      chatList.splice(findIndex, 1);
    }
    const updatedConversation = {
      ...selectedConversation,
      chatList,
    };

    const { single, all } = updateConversation(updatedConversation, conversations);
    homeDispatch({ field: 'selectedConversation', value: single });
    homeDispatch({ field: 'conversations', value: all });
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isTyping && !e.shiftKey) {
      e.preventDefault();
      handleEditMessage();
    }
  };

  const copyOnClick = () => {
    if (!navigator.clipboard) return;

    navigator.clipboard.writeText(message.chatContent).then(() => {
      setMessageCopied(true);
      setTimeout(() => {
        setMessageCopied(false);
      }, 2000);
    });
  };

  const handleLikeChat = async () => {
    if (!thumbUp) {
      const result: any = await reqLikeMessage(message.chatId);

      if (result.status === 0) {
        toast.success('点赞成功！感谢您的反馈！');
        setThumbUp(!thumbUp);
      } else {
        toast.error('点赞失败，请再试一次...');
      }
    } else {
      toast.error('您已经点赞过了，请不要再次操作！');
    }
  };

  const handleUnlikeChat = async () => {
    if (!thumbDown) {
      const result: any = await reqFeedbackMessage({ chatId: message.chatId });
      if (result.status === 0) {
        toast.success('点踩成功！感谢您的反馈！');
        setThumbDown(!thumbDown);
      } else {
        toast.error('点踩失败，请再试一次...');
      }
    } else {
      toast.error('您已经点踩过了，请不要再次操作！');
    }
  };

  const handleUpdateFeedbackContent = (e: any) => {
    setFeedbackContent(e.target.value);
  };

  const handleCheckout = (e: any) => {
    const value = e.target.value;
    if (value === 'isUnSafe') {
      setIsUnSafe(!isUnSafe);
    } else if (value === 'isUseless') {
      setIsUseless(!isUseless);
    } else {
      setIsNotTrue(!isNotTrue);
    }
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
    setIsUnSafe(false);
    setIsUseless(false);
    setIsNotTrue(false);
    setFeedbackContent('');
  };

  const handleFeedback = async () => {
    const feedback = {
      chatId: message.chatId,
      feedbackContent,
      isUnSafe,
      isUseless,
      isNotTrue,
    };
    const result: any = await reqFeedbackMessage(feedback);
    if (result.status === 0) {
      toast.success('提交成功！感谢您的反馈！');
      setThumbDown(!thumbDown);
    } else {
      toast.error('反馈失败，请再试一次...');
    }
    setShowFeedback(false);
  };

  useEffect(() => {
    setMessageContent(message.chatContent);
  }, [message.chatContent]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [isEditing]);

  return (
    <div
      className={`group md:px-4 ${message.chatRole === 'assistant'
        ? 'border-b border-black/10 bg-gray-50 text-gray-800 dark:border-gray-900/50 dark:bg-[#444654] dark:text-gray-100'
        : 'border-b border-black/10 bg-white text-gray-800 dark:border-gray-900/50 dark:bg-[#343541] dark:text-gray-100'
        }`}
      style={{ overflowWrap: 'anywhere' }}
    >
      <div className="relative m-auto flex p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-2xl lg:px-0 xl:max-w-3xl">
        <div className="min-w-[40px] text-right font-bold">
          {message.chatRole === 'assistant' ? (
            // <IconRobot size={30} />
            <img alt="Deep Chat" src={DeepChat} style={{ width: 30, borderRadius: 5 }} />
          ) : (
            // <IconUser size={30} />
            <img alt="User" src={User} style={{ width: 30, borderRadius: 5 }} />
          )}
        </div>

        <div className="prose mt-[-2px] w-full dark:prose-invert">
          {message.chatRole === 'user' ? (
            <div className="flex w-full">
              {isEditing ? (
                <div className="flex w-full flex-col">
                  <textarea
                    ref={textareaRef}
                    className="w-full resize-none whitespace-pre-wrap border-none dark:bg-[#343541]"
                    value={messageContent}
                    onChange={handleInputChange}
                    onKeyDown={handlePressEnter}
                    onCompositionStart={() => setIsTyping(true)}
                    onCompositionEnd={() => setIsTyping(false)}
                    style={{
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                      padding: '0',
                      margin: '0',
                      overflow: 'hidden',
                    }}
                  />

                  <div className="mt-10 flex justify-center space-x-4">
                    <button
                      className="h-[40px] rounded-md bg-blue-500 px-4 py-1 text-sm font-medium text-white enabled:hover:bg-blue-600 disabled:opacity-50"
                      onClick={handleEditMessage}
                      disabled={messageContent.trim().length <= 0}
                    >
                      {'保存并提交'}
                    </button>
                    <button
                      className="h-[40px] rounded-md border border-neutral-300 px-4 py-1 text-sm font-medium text-neutral-700 hover:bg-neutral-100 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800"
                      onClick={() => {
                        setMessageContent(message.chatContent);
                        setIsEditing(false);
                      }}
                    >
                      {'取消'}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="prose whitespace-pre-wrap dark:prose-invert flex-1">
                  {message.chatContent}
                </div>
              )}

              {/* {!isEditing && (
                <div className="md:-mr-8 ml-1 md:ml-0 flex flex-col md:flex-row gap-4 md:gap-1 items-center md:items-start justify-end md:justify-start">
                  <button
                    className="invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    onClick={toggleEditing}
                  >
                    <IconEdit size={20} />
                  </button>
                  <button
                    className="invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    onClick={handleDeleteMessage}
                  >
                    <IconTrash size={20} />
                  </button>
                </div>
              )} */}
            </div>
          ) : (
            <div className="flex flex-row">
              <MemoizedReactMarkdown
                className="prose dark:prose-invert flex-1"
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax]}
                components={{
                  code({ node, inline, className, children, ...props }: any) {
                    if (children.length) {
                      if (children[0] == '▍') {
                        return <span className="animate-pulse cursor-default mt-1">▍</span>;
                      }

                      children[0] = (children[0] as string).replace('`▍`', '▍');
                    }

                    const match = /language-(\w+)/.exec(className || '');

                    return !inline ? (
                      <CodeBlock
                        key={Math.random()}
                        language={(match && match[1]) || ''}
                        value={String(children).replace(/\n$/, '')}
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  table({ children }: any) {
                    return (
                      <table className="border-collapse border border-black px-3 py-1 dark:border-white">
                        {children}
                      </table>
                    );
                  },
                  th({ children }: any) {
                    return (
                      <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white">
                        {children}
                      </th>
                    );
                  },
                  td({ children }: any) {
                    return (
                      <td className="break-words border border-black px-3 py-1 dark:border-white">
                        {children}
                      </td>
                    );
                  },
                }}
              >
                {`${message.chatContent}${messageIsStreaming &&
                  messageIndex == (selectedConversation?.chatList.length ?? 0) - 1
                  ? '`▍`'
                  : ''
                  }`}
              </MemoizedReactMarkdown>

              <div className="md:-mr-8 ml-1 md:ml-0 flex flex-col md:flex-row gap-4 md:gap-1 items-center md:items-start justify-end md:justify-start">
                {messagedCopied ? (
                  <IconCheck size={20} className="mr-3 text-green-500 dark:text-green-400" />
                ) : (
                  <button
                    className="mr-3 invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    onClick={copyOnClick}
                  >
                    <IconCopy size={20} />
                  </button>
                )}

                {/* 点赞功能 */}
                <button
                  className="mr-3 invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  disabled={thumbDown}
                  onClick={handleLikeChat}
                >
                  {thumbUp ? <IconThumbUpFilled size={20} /> : <IconThumbUp size={20} />}
                </button>
                {/* 点踩功能 */}
                <button
                  className="mr-3 invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  disabled={thumbUp}
                  onClick={handleUnlikeChat}
                >
                  {thumbDown ? <IconThumbDownFilled size={20} /> : <IconThumbDown size={20} />}
                </button>
                {/* 反馈功能 */}
                <button
                  className="invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowFeedback(true)}
                >
                  <IconAlertTriangle size={22} />
                </button>
                <div
                  className={`smegma-content ${showFeekback ? '' : 'smegma-content-hidden'}`}
                  onClick={handleCloseFeedback}
                ></div>
                <div
                  className={`feedback-modal light-style-dark ${showFeekback ? '' : 'feedback-modal-hidden'
                    } ${lightMode === 'light' ? 'light-style-light' : 'light-style-dark'}`}
                >
                  <h1 className="feedback-title">
                    <IconAlertTriangle size={20} />
                    提交反馈
                  </h1>
                  <button className="close-btn" onClick={handleCloseFeedback}>
                    <IconX size={20} />
                  </button>
                  <div className="feedback-content">
                    <textarea
                      className="feedback-input"
                      name="feedback"
                      placeholder="请输入您的意见与反馈"
                      cols={45}
                      rows={4}
                      value={feedbackContent}
                      onChange={handleUpdateFeedbackContent}
                    />
                    <div>
                      <input
                        className="feedback-select"
                        type="checkbox"
                        color="#444653"
                        value="isUnSafe"
                        onChange={handleCheckout}
                      />{' '}
                      包含有害内容
                    </div>
                    <div>
                      <input
                        className="feedback-select"
                        type="checkbox"
                        value="isNotTrue"
                        onChange={handleCheckout}
                      />{' '}
                      内容不正确
                    </div>
                    <div>
                      <input
                        className="feedback-select"
                        type="checkbox"
                        value="isUseless"
                        onChange={handleCheckout}
                      />{' '}
                      没有帮助
                    </div>
                  </div>
                  <button className="feedback-submit" onClick={handleFeedback}>
                    提交反馈
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <Toaster /> */}
    </div>
  );
});
ChatMessage.displayName = 'ChatMessage';
