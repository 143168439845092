import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Logo from '../../../assets/images/logo.png';

function Privacy() {
  return (
    <div className="protocal">
      <div className='container mx-auto mb-10'>
        <Link to="/">
          <img src={Logo} alt="logo" width={120} className="-ml-4" />
        </Link>
        <h2>语鲸大模型隐私协议</h2>
        <p>欢迎您使用语鲸大模型服务，语鲸大模型提供者（以下称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，遵循合法、正当、必要和诚信原则处理您的个人信息。因此制定本《隐私协议》（以下称“本协议”）以便您充分了解，在您使用语鲸大模型时，我们会如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
        </p>
        <p>在您使用语鲸大模型前，请您务必认真阅读本协议，充分理解各条款内容，您可以按照本隐私协议的指引做出您认为适当的选择。本隐私协议中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。如有任何疑问，请联系我们。</p>
        <p>您同意本政策表示您已了解我们所提供的服务及功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权。如果您或您的监护人不同意本政策的任何内容，请您立即停止使用。当您继续使用本产品或相关各项服务时，即表示您已同意我们按照本政策来合法收集、使用和存储您的个人信息。在将您的信息用于本政策未涵盖的用途时，我们会事先征求您的同意。</p>
        <p>本协议将帮助您了解以下内容：</p>
        <p>（1）我们如何收集和使用您的个人信息</p>
        <p>（2）我们如何使用 Cookie 或同类技术</p>
        <p>（3）我们如何共享、转让、公开披露您的个人信息</p>
        <p>（4）我们如何保护您的个人信息</p>
        <p>（5）您如何管理您的个人信息</p>
        <p>（6）我们如何处理未成年人的个人信息</p>
        <p>（7）本隐私协议如何</p>
        <dl>
          <dt>1. 我们如何收集和使用您的个人信息</dt>
          <p>我们会根据正当、合法、公开、透明、最小必要的原则，基于本政策所述的目的，收集和使用您的个人信息。为了向您提供更优质和智能的产品和服务，我们可能需要收集下述信息，以下我们将详细说明语鲸收集和使用的个人信息。如果您拒绝提供，将无法享用对应的业务功能和服务。</p>
          <dd>1.1向您提供产品与服务过程中您主动提供的个人信息</dd>
          <dd>1.1.1 账户信息</dd>
          <dd>（1）当您申请使用语鲸，可以登录经注册的语鲸账号，您需要向我们提供您的手机号以及验证码以完成注册及登录。</dd>
          <dd>（2）如您不登录语鲸账号，我们不会收集您的上述个人信息，但与登录相关的功能将无法使用。</dd>
          <dd>1.1.2 人机交互对话</dd>
          <dd>除非法律法规另有规定的，为向您提供连续性、一致化的使用体验，保障服务质量，在您使用语鲸的服务时，我们将收集您与语鲸进行交互过程中所输入的文本对话信息，使用这些信息向您输出内容，以提供人机交互服务。您的文本对话信息还会被用来提高语鲸的响应速度和质量。</dd>
          <dd>在对话过程中，您可以对语鲸所输出的内容进行反馈评价。我们将收集您的反馈信息，包括您主动提交的内容，以便不断改进语鲸的输出内容质量。请注意，根据具体应用场景，您与在使用语鲸服务的过程中，所输入的内容可能会包含个人信息。<span>如您不希望我们收集和处理前述个人信息的，请您谨慎输入前述信息，但因此您可能会影响您正常使用语鲸提供的部分或全部功能</span>。</dd>
          <dd>1.1.3 问答历史</dd>
          <dd>为向您提供连续性、一致化的使用体验，保障服务质量，我们会收集您的问答历史，包括您输入的文本对话信息以及基于上述信息形成的对话主题。您可以查找并管理尚未删除的问答历史。为履行网络信息安全义务，我们会在法律规定的时间内保留您的问答历史及对话主题。</dd>
          <dd>1.1.4 其他用途</dd>
          <dd>（1）我们通过技术手段对个人信息进行匿名化处理后，匿名化处理的信息将无法识别主体。请<span>您了解并同意，在此情况下我们有权使用已经匿名化的信息</span>。</dd>
          <dd>（2）<span>在不透露您个人信息的前提下，签署本协议视为您已同意授权我们对您在使用本产品的过程中，所产生的各项数据（包括您的个人信息）进行分析并开展商业化利用的权利</span>。</dd>
          <dd>（3）当我们要将您的个人信息用于本规则未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。请您理解，我们向您提供的服务将不断更新变化。如果您选择使用本政策中尚未列明的其他功能时，在我们收集您的个人信息前，我们会通过更新协议、页面弹窗提示的方式向您详细说明信息收集的目的、方式、范围并征求您的明确同意。若您不同意提供前述信息，您可能无法使用该项服务，但不影响您使用其他服务。</dd>
          <dd>1.2 向您提供产品与/或服务过程中我们主动收集的个人信息</dd>
          <dd>1.2.1 日志信息</dd>
          <dd>当您使用语鲸的产品与服务时，我们会收集您的IP地址信息，以便于我们改善产品与服务，给您更好的服务体验。</dd>
          <dd>1.2.2 浏览器和操作信息</dd>
          <dd>请您注意，当您使用语鲸的产品与服务时，基于确保本产品使用安全的目的，我们会读取您的浏览器信息、操作终端信息。</dd>
          <dd>1.3 我们从第三方获取的您的个人信息</dd>
          <dd>我们可能会在您的同意范围内从第三方（我们的合作方）处收集并使用您的个人信息。我们保证依照相关法律法规的规定处理您的个人信息，同时请您详细阅读该第三方的隐私政策及用户服务协议。</dd>
          <dd>1.4 个人信息处理说明</dd>
          <dd>根据适用的法律法规，我们可能会对您的个人信息进行技术处理，使得根据该信息无法精确识别到用户个人，并对技术处理后的信息进行匿名化的学术研究或统计分析（包括使用您在使用会写功能时输入的对话信息），以便不断改进算法模型的识别和响应的速度和质量、提高会服务的智能性和对您输入内容的理解能力，但我们不会根据您输入的对话内容对您个人进行特定身份的识别。</dd>
          <dd>1.5 征得同意的例外</dd>
          <dd>根据法律法规，以下情形中收集和使用您的个人信息无需征得您的同意：</dd>
          <dd>（1）与国家安全、国防安全有关的。</dd>
          <dd>（2）与公共安全、公共卫生、重大公共利益有关的。</dd>
          <dd>（3）与犯罪侦查、起诉、审判和判决执行等有关的。</dd>
          <dd>（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。</dd>
          <dd>（5）所收集的个人信息是个人信息主体或监护人自行向社会公众公开的。</dd>
          <dd>（6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。</dd>
          <dd>（7）根据您的要求签订合同所必需的。</dd>
          <dd>（8）用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障。</dd>
          <dd>（9）为合法的新闻报道所必需的。</dd>
          <dd>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</dd>
          <dd>（11）法律法规规定的其他情形。</dd>
          <dd>（12）我们可能从第三方获取您授权共享的个人信息，我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</dd>
        </dl>
        <dl>
          <dt>2. 我们如何使用 Cookie 或同类技术</dt>
          <dd>我们可能通过使用 Cookie 或同类技术获取和使用您的信息，并将该信息存储为日志信息。</dd>
          <dd>通过使用 Cookie，我们向用户提供简单易行并富个性化的网络体验。一个 Cookie 是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用 Cookie 是为了让其用户可以受益。比如，为使得语鲸大模型的登录过程更快捷，您可以选择把用户名存在一个 Cookie 中。这样下次当您要使用语鲸大模型的服务时能更加方便快捷。 Cookie 能帮助我们确定您连接的页面和内容，您在语鲸大模型特定服务上花费的时间和您所选择的语鲸大模型服务。</dd>
          <dd> Cookie 使得我们能更好、更快地为您服务，并且使您在语鲸大模型服务上的经历更富个性化。您可以通过浏览器或用户选择机制拒绝或管理 Cookie 或同类技术。<span>但请您注意，如果您停用 Cookie 或同类技术，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用</span>。</dd>
          <dd>我们使用自己的 Cookie 或同类技术，可能用于以下用途：</dd>
          <dd>（1）记住您的身份。</dd>
          <dd>（2）分析您使用我们服务的情况。</dd>
          <dd>如您希望禁用 Cookie ，您可以对浏览器进行配置：选择“拒绝 Cookie ”或“当网站发送 Cookie 时通知您”。鉴于我们的服务是通过支持 Cookie 来实现的，完成关闭 Cookie 的操作后，您的访问可能受到影响或不能充分获得我们的服务。您不禁用 Cookie 时，可能会得到提示，例如是否在下一次进入此网站时保留用户信息以便简化登录手续（如自动登录）。</dd>
        </dl>
        <dl>
          <dt>3. 我们如何共享、转让或公开披露您的个人信息</dt>
          <dd>3.1 委托处理</dd>
          <dd>为了向您提供更完善、更优质的产品和服务，我们可能会委托第三方（包括公司、组织和个人）处理您的某些个人信息。对我们委托处理您个人信息的第三方，我们会与其签署严格的数据处理协议，明确约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对第三方的个人信息处理活动进行监督，要求第三方仅按照我们的要求、本政策的规定以及其他任何相关的保密和安全措施来处理您的个人信息，不得超出约定的处理目的、处理方式等处理个人信息。如果您拒绝我们的第三方在提供服务时收集为提供服务所必需的个人信息，将可能导致您在使用产品与/或服务的过程中无法享受该第三方提供的服务。</dd>
          <dd>3.2 共享</dd>
          <dd>我们在遵守“合法正当、最小必要、目的明确原则”的前提下共享您的个人信息。同时，我们将对个人信息的共享活动事先进行个人信息保护影响评估，对输出形式、流转、使用等采取有效的技术保护措施。在合作协议层面，严格要求合作伙伴的信息保护义务与责任，与业务合作伙伴在合作前签署关于数据安全的保护协议。</dd>
          <dd>我们制定了《第三方共享清单》向您清晰、简洁的介绍我们主要的有关您个人信息共享情况。</dd>
          <dd>我们不会与深言之外的任何第三方共享您的信息，但以下情形除外：</dd>
          <dd>（1）事先获得您明确的同意或授权。</dd>
          <dd>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求对外共享您的信息。</dd>
          <dd>（3）只有共享您的个人信息，才能实现我们的产品和服务的核心功能或提供您需要的服务。</dd>
          <dd>（4）为实现基于我们向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账户与交易安全等，<span>我们可能会将您的个人信息与我们的关联方共享。我们将会根据本隐私协议的约定与我们的关联方共享您的个人信息，但我们只会共享必要的个人信息，且受本隐私协议中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意</span>。</dd>
          <dd>（5）<span>我们可能会向合作伙伴等第三方共享您的输入字符串信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途</span>。</dd>
          <dd>（6）基于学术研究而提供。</dd>
          <dd>（7）根据与您签署的单项服务协议或其他的法律文件约定所提供。</dd>
          <dd><span>您明确知悉在使用语鲸大模型时您邀请第三人或其他账号参与编辑/只读系统中的文档信息时，即视为您已授权第三人或其他账号共享此文档信息</span>。</dd>
          <dd>3.3 转让</dd>
          <dd>转让是指将个人信息控制权向其他公司、组织或个人转移的过程。</dd>
          <dd>我们不会与本公司以外的任何第三方转让您的个人信息，但以下情形除外：</dd>
          <dd>（1）事先获得您明确的同意或授权。</dd>
          <dd>（2）随着我们业务的持续发展，<span>在我们发生合并、收购或破产清算情形时，您的信息有可能作为此类交易的一部分而被转移。如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织、个人继续受本协议的约束，否则我们将要求该公司、组织、个人重新向您征求授权同意</span>。</dd>
          <dd>3.4 公开披露</dd>
          <dd>我们不会与本公司以外的任何第三方公开披露您的个人信息，但以下情形除外：</dd>
          <dd>（1）我们可能会根据法律法规、诉讼、争议解决的需要，或按行政、司法机关依法提出的要求公开披露您的个人信息。</dd>
          <dd>（2）根据您的需求，在您明确同意的披露方式下披露您所指定的信息。</dd>
        </dl>
        <dl>
          <dt>4. 我们如何保护您的个人信息</dt>
          <dd>我们一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术及组织和管理等保护措施以最大程度降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。</dd>
          <dd>4.1 数据安全技术措施</dd>
          <dd>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。</dd>
          <dd>我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。</dd>
          <dd>我们采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。</dd>
          <dd>在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。</dd>
          <dd>我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</dd>
          <dd>我们采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</dd>
          <dd>4.2 我们为保护个人信息采取的其他安全措施</dd>
          <dd>我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</dd>
          <dd>我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</dd>
          <dd>我们建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。</dd>
          <dd>我们会举办安全和隐私保护培训课程，加强员工的安全意识以及对于个人信息保护协议和相关规程的认识。</dd>
          <dd><span>请您知悉，虽然我们将尽力确保您发送给我们的任何信息的安全性，但互联网环境并非百分之百安全，我们亦不对由此产生或与之相关的任何风险、损失承担责任</span>。</dd>
          <dd><span>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关程序以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况</span>。</dd>
        </dl>
        <dl>
          <dt>5. 您如何管理您的个人信息</dt>
          <dd>我们非常重视您对个人信息的管理，并尽全力保障您对于个人信息的访问、更正、删除、撤回同意以及其他法定权利，使您拥有充分的能力维护您的隐私和个人信息安全。</dd>
          <dd>5.1 访问和更正您的个人信息</dd>
          <dd>除法律法规规定外，您有权随时访问和更正您的账户信息。	  对于您在使用我们的产品和服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私协议所列明的方式和期限响应您的请求。</dd>
          <dd>5.2 删除您的个人信息</dd>
          <dd>在以下情形中，您可以通过本协议载明的方式向我们提出删除个人信息的请求：</dd>
          <dd>（1）如果我们处理个人信息的行为违反法律法规。</dd>
          <dd>（2）如果您不再使用我们的产品和服务，或您注销账号。</dd>
          <dd>（3）如果我们停止提供产品和服务，或保存期限已届满。</dd>
          <dd>（4）如果您撤回同意。</dd>
          <dd>（5）如果我们处理个人信息的目的已实现、无法实现或为实现处理目的不再必要。</dd>
          <dd>5.3 改变或撤回您的同意</dd>
          <dd><span>您可以通过删除信息、关闭设备功能、在语鲸大模型网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权</span>。</dd>
          <dd>请您理解语鲸大模型可能需要收集必要的个人信息才能得以实现。<span>当您撤回同意时，我们将无法继续为您提供撤回同意所对应的服务，但不会影响此前基于您授权而开展的个人信息处理服务</span>。</dd>
          <dd>5.4 注销您的账户</dd>
          <dd>您可以通过线上注销的方式随时注销您此前注册的账户。</dd>
          <dd><span>在您注销账号后，该账号下的内容、信息、数据、记录、文档信息等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外）。同时，账号一旦注销完成，将无法恢复</span>。</dd>
          <dd>5.5 响应您的上述请求</dd>
          <dd>如您无法按照上述方式行使权力，您可以采用本协议载明的联系方式与我们联系。为保障您的账户安全，您向我们提出上述请求时，我们可能会先要求您验证自己的身份，然后再处理您的请求。</dd>
          <dd><span>对于您的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定的成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝</span>。</dd>
          <dd>5.6 响应情形的例外</dd>
          <dd>在以下情形中，我们将无法响应您的请求：</dd>
          <dd>（1）与我们履行法律法规规定的义务相关的。</dd>
          <dd>（2）与国家安全、国防安全直接相关的。</dd>
          <dd>（3）与公共安全、公共卫生、重大公共利益直接相关的。</dd>
          <dd>（4）与刑事侦查、起诉、审判和执行判决等直接相关的。</dd>
          <dd>（5）我们有充分证据表明个人信息主体存在主观恶意或滥用权利的。</dd>
          <dd>（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。</dd>
          <dd>（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的。</dd>
          <dd>（8）涉及商业秘密的。</dd>
        </dl>
        <dl>
          <dt>6. 我们如何处理未成年人的个人信息</dt>
          <dd>我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品和服务前，应事先取得您监护人的同意。我们将根据国家相关法律法规的规定保护未成年人的个人信息。</dd>
          <dd>我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</dd>
          <dd>如果有事实证明未成年人在未取得监护人同意的情况下使用了我们的产品和服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。</dd>
          <dd>对于未满14周岁的未成年人，我们除遵守本隐私协议关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。</dd>
          <dd>若您为未满14周岁的未成年人的父母或其他监护人，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。</dd>
        </dl>
        <dl>
          <dt>7. 如何更新本政策</dt>
          <dd>我们的隐私协议可能会适时发生变更。我们会在深言科技官网上发布对本隐私协议所做的任何变更。对于重大变更，我们会提供更为显著的通知，如通过在浏览页面做特别提示等方式，说明隐私协议的具体变更内容。未经您明确同意，我们不会削减您按照本隐私协议所应享有的权利。</dd>
          <dd>本隐私协议所指的重大变更包括但不限于：</dd>
          <dd>（1）我们的服务模式发生重大变化。</dd>
          <dd>（2）个人信息共享、转让或公开披露的主要对象发生变化。</dd>
          <dd>（3）您参与个人信息处理方面的权利及其行使方式发生重大变化。</dd>
          <dd>（4）我们的联系方式及投诉渠道发生变化。</dd>
          <dd>（5）个人信息安全影响评估报告表明存在高风险时。</dd>
          <dd>我们会将本隐私协议的旧版本存档，供您查阅。</dd>
        </dl>
        <dl>
          <dt>8. 如何联系我们</dt>
          <dd>如果您对本隐私协议有任何疑问、意见或建议，请通过以下方式与我们联系：</dd>
          <dd>联系电话：18610667802</dd>
          <dd>联系地址：北京市海淀区中关村东路8号东升大厦A座五层501</dd>
          <dd>电子邮箱：feedback@deeplang.ai</dd>
          <dd>一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</dd>
        </dl>
        <dl>
          <dt>附件 1：定义</dt>
          <dd>个人信息</dd>
          <dd>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或反应特定自然人活动情况的各种信息，包括姓名，出生日期，身份证号码，个人生物识别信息，住址，通信通讯联系方式，通信记录和内容，账号密码，财产信息，征信信息，行踪轨迹，住宿信息，健康生理信息，交易信息等。</dd>
          <dd>敏感个人信息</dd>
          <dd>一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息，例如，敏感个人信息包括身份证件号码、个人生物识别信息、银行账号、通信内容、健康生理信息等。</dd>
          <dd>授权同意</dd>
          <dd>是指您对您的个人信息进行特定处理作出明确授权的行为，包括通过积极的行为作出授权（即明示同意），也包括通过消极的不作为而作出授权。</dd>
          <dd>匿名化</dd>
          <dd>指通过对个人信息的技术处理，使得个人信息主体无法被识别或者被关联到，且处理之后的信息不能被复原的过程。</dd>
          <dd>去标识化</dd>
          <dd>个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</dd>
          <dd>日志信息</dd>
          <dd>您在使用我们平台时发布、浏览的内容，您发布内容时的操作时间、类型、网络原地址、目标地址、端口，访问的日期和时间，登录IP信息。</dd>
          <dd>设备信息</dd>
          <dd>包括设备标识符（IMEI、IDFA、Android ID、MAC、OAID、IMSI及其他设备相关信息）、应用信息（应用崩溃信息、通知开关状态、应用安装列表及其他应用相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息（IP地址，WiFi信息，基站信息及其他网络相关信息）。以产品实际采集情况为准。</dd>
          <dd>精准定位信息</dd>
          <dd>指 GPS 或基站信息，该信息属于敏感个人信息。为收集上述信息，我们需要申请您授权我们使用设备的位置权限。</dd>
          <dd>产品或服务名称</dd>
          <dd>是指语鲸大模型网站。</dd>
          <dd>产品或服务名称提供者</dd>
          <dd>是指北京深言科技有限责任公司。</dd>
          <dd>儿童</dd>
          <dd>是指未满十四周岁的未成年人。</dd>
        </dl>
      </div>
      <Footer />
    </div>
  )
}


export default Privacy;
