/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-11 15:03:09
 * @FilePath: /deep-chat/components/Chatbar/components/ChatFolders.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useContext, useEffect, useState } from 'react';

import { FolderInterface } from '../../../types/folder';

import HomeContext from '../../../pages/home/home.context';

import Folder from '../../../components/Folder';

import { useCreateReducer } from '../../../hooks/useCreateReducer';
import { HomeInitialState, initialState } from '../../../pages/home/home.state';
import { getSessions } from '../../../utils/app/session';
import { ConversationComponent } from './Conversation';

import cookie from 'react-cookies';
interface Props {
  searchTerm: string;
  isFolderUpdate: boolean;
}

export const ChatFolders = ({ searchTerm, isFolderUpdate }: Props) => {
  const contextValue = useCreateReducer<HomeInitialState>({
    initialState,
  });

  const {
    // state: { userId, selectedSession },
    dispatch,
  } = contextValue;
  const { state: { userId } } = useContext(HomeContext);
  const [folders, setFolders] = useState([]);
  // useEffect(() => {
  //   getConversationList();
  // }, [])

  useEffect(() => {
    getConversationList();
  }, [isFolderUpdate]);

  const handleDrop = (e: any, folder: FolderInterface) => {
    // if (e.dataTransfer) {
    //   const session = JSON.parse(e.dataTransfer.getData('session'));
    //   handleUpdateSession(session, {
    //     key: 'folderId',
    //     value: folder.id,
    //   });
    // }
  };

  const getConversationList = async () => {
    const currentUserId = userId;
    const sessionList = await getSessions(currentUserId);
    setFolders(sessionList);
  };

  const ChatFolders = (currentSessions: any) => {
    return (
      currentSessions &&
      currentSessions.map((session: any, index: any) => {
        return (
          <div key={session.sessionId} className="ml-5 gap-2 border-l pl-2">
            <ConversationComponent session={session} />
          </div>
        );
      })
    );
  };

  return (
    <div className="flex w-full flex-col pt-2">
      {folders?.map((folder: any, index: number) => (
        <Folder
          key={index}
          searchTerm={searchTerm}
          currentFolder={folder}
          handleDrop={handleDrop}
          folderComponent={ChatFolders(folder.sessions)}
        />
      ))}
    </div>
  );
};
