import { createContext, useContext } from 'react';
import { Options, DeepGuardStore } from './type';
import useStore from './store/useStore';

export const DeepGuardContext = createContext<any>({});

export const useDeepGuard = () => {
  const state = useContext<DeepGuardStore & Options>(DeepGuardContext);
  return state;
};

export function DeepGuardProvider(options: Options): JSX.Element {
  const { children, source, appId, mode } = options;
  const store = useStore({ source, appId, mode });

  return (
    <DeepGuardContext.Provider value={{ ...options, ...store }}>
      {children}
    </DeepGuardContext.Provider>
  );
}
