import { Button, Checkbox, Divider, Form, Input, Space, Tooltip, message } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash-es';
import styles from './index.module.css';
import styleCommon from '../style/index.module.css';
import {
  MobileActions,
  ModalShowType,
  LoginType,
  errorText,
  deepGuardConfig,
  ProductSource,
} from '../../index';
import useCountdown from '../../hooks/useCountDown';
import { useDeepGuard } from '../../index';

import WechatImage from '../../assets/wechat.url.svg';
import WechatImageHover from '../../assets/wechatHover.url.svg';
import inputSuffix from '../../assets/inputSuffix.url.svg';
import inputSuffixSee from '../../assets/inputSuffixSee.url.svg';

import to from '../../utils/await-to';
import storage from '../../utils/storage';

type FieldType = {
  phone?: string;
  password?: string;
  agree?: string;
  phoneCode?: string;
};

const tabItems = [
  {
    label: '验证码登录',
    type: LoginType.CODE_LOGIN,
  },
  {
    label: '密码登录',
    type: LoginType.PASSWORD_LOGIN,
  },
];

const LoginForm = ({
  type = LoginType.CODE_LOGIN,
  width = 294,
}: {
  type?: LoginType;
  width?: number;
}) => {
  const deepGuard = useDeepGuard();

  const [loginType, setLoginType] = useState(type);
  const [form] = Form.useForm();
  const [isClickAgree, setIsClickAgree] = useState(false);
  const [isHoverWechat, setIsHoverWechat] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isSignUpProduct, setIsSignUpProduct] = useState(false);
  const phoneData = useRef({ phone: '', code: '' });
  const [phone, setPhone] = useState('');

  const formRef = useRef(null);
  const { config } = deepGuardConfig.getConfig();

  const { second, start, reset, isCounting } = useCountdown(60, () => {
    reset();
  });

  let phoneTimer: string | number | NodeJS.Timer | undefined;

  const verificationCodeBtnText = useMemo(() => {
    if (isCounting) {
      return `${second}秒`;
    }
    return '获取验证码';
  }, [isCounting, second]);

  useEffect(() => {
    window.addEventListener('message', deepGuard.receiveMessage, false);

    return () => {
      window.removeEventListener('message', deepGuard.receiveMessage);
      form.resetFields();
    };
  }, []);

  const onFinish = async (values: any) => {
    if (loginType === LoginType.CODE_LOGIN) {
      const { phone, phoneCode, agree } = values;
      if (!agree) {
        setIsClickAgree(true);
        return;
      }
      setButtonLoading(true);

      phoneData.current = {
        phone,
        code: phoneCode,
      };

      // const [checkErr, checkResult] = await to(deepGuard.checkProduct(phone));
      // if (checkErr) {
      //   setButtonLoading(false);
      //   if (checkErr.code === 204) {
      //     form.setFields([{ name: 'phoneCode', errors: [checkErr.msg] }]);
      //   } else {
      //     message.error(checkErr.msg);
      //   }
      //   return;
      // }

      // const checkResultArr = checkResult ? checkResult : [];

      // const positionArr = checkResultArr.filter((item) => item?.source === ProductSource.Extension);
      // if (checkResultArr.length > 0 && positionArr.length === 0) {
      //   setIsSignUpProduct(true);
      //   setButtonLoading(false);
      //   return;
      // }
      confirmLogin('codeLogin');
    }

    if (loginType === LoginType.PASSWORD_LOGIN) {
      const [err] = await to(
        deepGuard.loginAccount({
          account: values.phone,
          pwd: values.password,
        }),
      );
      setButtonLoading(false);
      if (err) {
        if (err.code === 209) {
          form.setFields([{ name: 'password', errors: [errorText(err.code)] }]);
        } else {
          message.error(err.msg);
        }
        return;
      }
      storage.set('loginType', LoginType.PASSWORD_LOGIN);
      deepGuard.hide();
    }
  };

  const confirmLogin = async (type = 'codeLogin') => {
    type === 'authorizedLogin' && setButtonLoading(true);

    const [err, result] = await to(deepGuard.loginMobile(phoneData.current));
    setButtonLoading(false);
    if (err) {
      if (type === 'codeLogin') {
        if (err.code === 204) {
          form.setFields([{ name: 'phoneCode', errors: [errorText(err.code)] }]);
        } else {
          message.error(err?.msg);
        }
      } else {
        message.error(err.msg);
      }
      return;
    }
    if (result) {
      if (!result.set_pwd) {
        deepGuard.show(ModalShowType.SettingPwd);
        return;
      }
      storage.set('loginType', LoginType.CODE_LOGIN);
      deepGuard.hide();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    clearInterval(phoneTimer);
    return () => clearInterval(phoneTimer);
  }, []);

  const handleGetCodeDebounce = useCallback(
    debounce(async () => {
      getCode();
    }, 200),
    [],
  );

  const handleForgetPassword = async () => {
    // const phoneNumber = await form.validateFields(['phone']);
    // if (phoneNumber.phone === '') {
    //   form.setFields([{ name: 'phone', errors: ['请输入手机号'] }]);
    //   return;
    // }
    deepGuard.show({ showType: ModalShowType.AuthenticatedPwdForget });
  };

  const onValuesChange = (values: any) => {
    if (values.phone !== undefined) {
      setPhone(values.phone);
    }
  };

  const getCode = async () => {
    const phoneNumber = await form.validateFields(['phone']);
    if (phoneNumber.phone === '') {
      form.setFields([{ name: 'phone', errors: ['请输入手机号'] }]);
      return;
    }

    const [err] = await to(
      deepGuard.sendMobileCode({
        phone: phoneNumber.phone,
        action: MobileActions.Login,
      }),
    );

    if (err) {
      message.error(err.msg);
      return;
    }
    start();
  };

  const tipContent = (): JSX.Element => {
    return (
      <div className={styles.cancellateConfirm}>
        <h3 className={cx(styleCommon.mb24, styles.confirmTitle)}>该手机号已注册深言账号</h3>
        <Button type="primary" block onClick={() => confirmLogin('authorizedLogin')}>
          授权注册并登录本产品
        </Button>
        <Button
          block
          className={cx(styleCommon.fullWidth, styleCommon.mt8)}
          onClick={() => setIsSignUpProduct(false)}
        >
          返回登录注册
        </Button>
      </div>
    );
  };

  const LoginForm = (): JSX.Element => {
    return (
      <Form
        name="登录"
        ref={formRef}
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{ agree: false, phone: phone }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <div className={styles.formContent} style={{ width: `${width}px` }}>
          <div className={styles.tabs}>
            {tabItems.map((item, i) => (
              <div
                key={i}
                className={cx(styles.tabItems, {
                  [styles.tabItemsActive]: loginType === item.type,
                })}
                onClick={() => {
                  form.resetFields();
                  setLoginType(item.type);
                  setButtonLoading(false);
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
          {loginType === LoginType.CODE_LOGIN ? (
            <>
              <Form.Item<FieldType>
                name="phone"
                rules={[
                  { required: true, message: '请输入手机号' },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '请输入正确的手机号',
                  },
                ]}
                normalize={(value) => {
                  value = value && value.replace(/[^\d]+/g, '');
                  return value;
                }}
                className={styleCommon.formItemContent}
              >
                <Input placeholder="请输入手机号" allowClear />
              </Form.Item>
              {/* <FormItemPhone onChange={onNumberChange} /> */}
              <Form.Item
                name="phoneCodeGroup"
                wrapperCol={{ span: 24 }}
                className={cx(styleCommon.mb0, styleCommon.formItemContent)}
              >
                <Space align="start">
                  <Form.Item
                    name="phoneCode"
                    rules={[{ required: true, message: '请输入验证码' }]}
                    className={styles.formItemContent}
                  >
                    <Input name="phoneCode" placeholder="请输入验证码" allowClear />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={isCounting}
                      className={cx({
                        [styleCommon.btnDisabled]: isCounting,
                      })}
                      onClick={() => handleGetCodeDebounce()}
                    >
                      {verificationCodeBtnText}
                    </Button>
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item<FieldType>
                name="agree"
                valuePropName="checked"
                className={cx(styleCommon.formItemCheck, { [styles.shaking]: isClickAgree })}
              >
                <Checkbox>
                  <span className={styleCommon.fs12}>
                    未注册手机号将自动注册，注册即代表同意 DeepLang 《
                    <a href={config.privacyPolicyUrl} target="_blank" rel="noreferrer"  className={styleCommon.textBlue}>
                      隐私政策
                    </a>
                    》、《
                    <a href={config.userProtocolUrl} target="_blank" rel="noreferrer"  className={styleCommon.textBlue}>
                      服务协议
                    </a>
                    》
                    {/* 及《
                    <a href={config.copyrightUrl} target="_blank" rel="noreferrer">
                      版权免责声明
                    </a>
                    》 */}
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item className={styleCommon.formItemContent}>
                <Button
                  type="primary"
                  className={cx(styleCommon.fullWidth)}
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  登录/注册
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item<FieldType>
                className={styleCommon.formItemContent}
                name="phone"
                rules={[
                  { required: true, message: '请输入账号' },
                  // {
                  //   pattern: /^1[3456789]\d{9}$/,
                  //   message: '请输入正确的手机号',
                  // },
                ]}
                // normalize={(value) => {
                //   value = value && value.replace(/[^\d]+/g, '');
                //   return value;
                // }}
              >
                <Input placeholder="请输入账号" allowClear />
              </Form.Item>
              <Form.Item<FieldType>
                className={styleCommon.formItemContent}
                name="password"
                rules={[
                  {
                    required: true,
                    message: '密码不能为空',
                  },
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="请输入登录密码"
                  allowClear
                  iconRender={(visible) =>
                    visible ? <img src={inputSuffixSee} alt="" /> : <img src={inputSuffix} alt="" />
                  }
                />
              </Form.Item>
              <Form.Item className={cx(styleCommon.formItemContent, styleCommon.mb0)}>
                <Button
                  type="primary"
                  className={cx(styleCommon.fullWidth)}
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  登录
                </Button>
              </Form.Item>
              <Form.Item className={cx(styleCommon.formItemContent, styleCommon.mb12)}>
                <div className={cx(styleCommon.flex, styleCommon.justifyEnd)}>
                  <span
                    className={cx(styles.formForget, styleCommon.fs12)}
                    onClick={handleForgetPassword}
                  >
                    忘记密码
                  </span>
                </div>
              </Form.Item>
            </>
          )}
          {/* <Divider className={styles.divider}>
            <span className={cx(styleCommon.fs12, styleCommon.textGrey)}>第三方快捷登录</span>
          </Divider>
          <div className={styles.footer}>
            <Tooltip
              title="微信"
              destroyTooltipOnHide={true}
              overlayClassName="category-tab-tooltip"
            >
              <div
                onMouseEnter={() => {
                  setIsHoverWechat(true);
                }}
                onMouseLeave={() => {
                  setIsHoverWechat(false);
                }}
                className={styleCommon.cursor}
              >
                {isHoverWechat ? (
                  <img src={WechatImageHover} alt="weixin" onClick={deepGuard.loginQRCode} />
                ) : (
                  <img src={WechatImage} alt="weixin" onClick={deepGuard.loginQRCode} />
                )}
              </div>
            </Tooltip>
          </div> */}
          <div className={cx(styles.footerText, styleCommon.fs12)}>
            <a href="/help" target="blank">
              遇到问题?
            </a>
          </div>
        </div>
      </Form>
    );
  };

  return <>{isSignUpProduct ? tipContent() : LoginForm()}</>;
};

export default LoginForm;
