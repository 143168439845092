import { IState, IUseShareReturn, TStateValue } from '../type';
import apiUser from '../api/user';
import to from '../utils/await-to';
import { removeAuthInfo } from '../utils';
import { EventKeys } from '../utils/pubsub';
import { useState } from 'react';
import { produce } from 'immer';
import { ModalShowType } from '../utils/constants';

// interface UserProps extends Options {
//   state: IState;
//   updateState: (value: any) => void;
// }

export const defaultUserInfo = {
  auth_token: '',
  access_token: '',
  user_name: '',
  icon: '',
  uid: '',
  b_id: '',
  binding_phone: false,
  phone: '',
  set_pwd: false,
  open_info: '',
  binding_wechat: false,
};

function useShare(): IUseShareReturn {
  // const { source } = props;

  const [state, setState] = useState<IState>({
    showType: ModalShowType.Login,
    modalVisible: false,
    hadLogin: false,
    userInfo: { ...defaultUserInfo },
    openInfo: '',
    loginType: 'code',
    loading: true,
  });

  const updateState = (options: Partial<IState> | string, value?: TStateValue) => {
    setState((prev) => {
      let newStatus = prev;
      if (typeof options === 'string' && value) {
        newStatus = produce(newStatus, (draft: any) => {
          draft[options] = value;
        });
      }
      if (typeof options === 'object') {
        newStatus = produce(newStatus, (draft) => {
          Object.assign(draft, options);
        });
      }
      return newStatus;
    });
  };

  const logout = async () => {
    const [err, result] = await to(apiUser.logout());
    removeAuthInfo();
    PubSub.publish(EventKeys.LOGOUT, [err, result]);
    if (err) return Promise.reject(err);
    updateState({ userInfo: { ...defaultUserInfo }, hadLogin: false });
    return result;
  };

  return { state, updateState, logout };
}

export default useShare;
