import { IUserApi } from './type';
import request from '../utils/request';

const api: IUserApi = {
  getUserinfo() {
    return request({
      url: '/api/user/userinfo',
      method: 'post',
    });
  },
  updatePwd(data) {
    return request({
      url: '/api/user/pwd_update',
      method: 'post',
      data,
    });
  },
  updateUserInfo(data) {
    return request({
      url: '/api/user/userinfo_update',
      method: 'post',
      data,
    });
  },
  logout() {
    return request({
      url: '/api/user/logout',
      method: 'post',
    });
  },
  destroy() {
    return request({
      url: '/api/user/destroy',
      method: 'post',
    });
  },
  refreshToken() {
    return request({
      url: '/api/user/refresh_token',
      method: 'post',
    });
  },
  checkUserPhone(data) {
    return request({
      url: '/api/user/userinfo_check',
      method: 'post',
      data,
    });
  },
};

export default api;
