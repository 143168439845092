/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-03 16:00:05
 * @FilePath: /deep-chat/components/Chatbar/Chatbar.context.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Dispatch, createContext } from 'react';

import { ActionType } from '../../hooks/useCreateReducer';

import { Session } from '../../types/chat';
import { SupportedExportFormats } from '../../types/export';
import { ChatbarInitialState } from './Chatbar.state';

export interface ChatbarContextProps {
  state: ChatbarInitialState;
  dispatch: Dispatch<ActionType<ChatbarInitialState>>;
  handleDeleteSession: (session: Session) => void;
  handleRenameSession: (renameValue: string, session: Session | undefined) => void;
  handleClearSessions: () => void;
  handleExportData: () => void;
  handleImportSessions: (data: SupportedExportFormats) => void;
}

const ChatbarContext = createContext<ChatbarContextProps>(undefined!);

export default ChatbarContext;
