import { ILoginApi } from './type';
import request from '../utils/request';

const api: ILoginApi = {
  sendCode(data) {
    return request({
      url: '/api/login/send_code',
      method: 'post',
      data,
    });
  },
  mobileSms(data) {
    return request({
      url: '/api/login/phone_sms',
      method: 'post',
      data,
    });
  },
  loginAccount(data) {
    return request({
      url: '/api/login/account',
      method: 'post',
      data,
    });
  },
  getQRCodeUrl(data) {
    return request({
      url: '/api/login/wx_qrcode',
      method: 'post',
      data,
    });
  },
  loginWeChat(data) {
    return request({
      url: '/api/login/wechat',
      method: 'post',
      data,
    });
  },
  loginWeApp(data) {
    return request({
      url: '/api/login/weapp',
      method: 'post',
      data,
    });
  },
  forgetPwd(data) {
    return request({
      url: '/api/login/forget_pwd',
      method: 'post',
      data,
    });
  },
  checkProduct(data) {
    return request({
      url: '/api/login/mobile_check',
      method: 'post',
      data,
    });
  },
};

export default api;
