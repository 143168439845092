import PubSub from 'pubsub-js';

export default PubSub;

export const EventKeys = {
  LOGIN: 'LOGIN',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  DESTROYED: 'DESTROYED',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
};
