/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Lemon.Feng
 * @LastEditTime: 2023-06-30 01:01:45
 * @FilePath: /deep-chat/components/Chatbar/Chatbar.state.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Session } from '../../types/chat';

export interface ChatbarInitialState {
  searchTerm: string;
  filteredSessions: Session[];
}

export const initialState: ChatbarInitialState = {
  searchTerm: '',
  filteredSessions: [],
};
