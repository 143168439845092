import { DeepGuardStore, Options } from '../type';

import useLogin from '../hooks/useLogin';
import useUser from '../hooks/useUser';
import useActions from '../hooks/useActions';
import useShare from '../hooks/useShare';
import { ModalShowType } from '../utils/constants';

function useStore(props: Options): DeepGuardStore {
  const { state, updateState, logout } = useShare();

  const shareProps = {
    ...props,
    state,
    updateState,
    logout,
  };

  const userStore = useUser(shareProps);
  const loginStore = useLogin(shareProps);
  const actionsStore = useActions(shareProps);

  const receiveMessage = (event: { type: string; data: any; origin: string }) => {
    const { type, data } = event.data;
    if (type === 'wechat-login-success' && event.origin.includes('auth.shenyandayi.com')) {
      console.log('wechat-login-success========1', event);
      if (data.binding_phone) {
        actionsStore.hide();
        loginStore.loginWeChat(data);
      } else {
        actionsStore.show(ModalShowType.MobileBind);
        updateState({ openInfo: data.open_info });
      }
    }
  };

  return {
    ...userStore,
    ...loginStore,
    ...actionsStore,
    state,
    updateState,
    receiveMessage,
    userInfo: state.userInfo,
  };
}

export default useStore;
