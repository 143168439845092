/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-21 16:42:02
 * @FilePath: /deep-chat/utils/app/settings.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Settings } from '../../types/settings';

const STORAGE_KEY = 'settings';

export const getSettings = (): Settings => {
  // 默认主题dark
  let settings: Settings = {
    theme: 'dark',
  };
  const settingsJson = localStorage.getItem(STORAGE_KEY);
  if (settingsJson) {
    try {
      // 获取缓存数据并进行浅比较
      let savedSettings = JSON.parse(settingsJson) as Settings;
      settings = Object.assign(savedSettings, settings);
    } catch (e) {
      console.error(e);
    }
  }
  return settings;
};

export const saveSettings = (settings: Settings) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
  localStorage.setItem('theme', settings.theme);
};
