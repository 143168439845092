import { Suspense, useEffect } from 'react';
import { GuardProvider } from '@authing/guard-react18';
import '@authing/guard-react18/dist/esm/guard.min.css';
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomeContext from './pages/home/home.context';
import { HomeInitialState, initialState } from './pages/home/home.state';

import { reqNewSession, reqUpdateSession } from './api';
import { useCreateReducer } from './hooks/useCreateReducer';
import { saveFolders } from './utils/app/folders';
import { savePrompts } from './utils/app/prompts';
import { saveSession, saveSessions } from './utils/app/session';

import { Session } from './types/chat';
import { FolderInterface, FolderType } from './types/folder';
import { Prompt } from './types/prompt';
import './App.less';

// page
import Home from './pages/home';
// import Login from './pages/login';
import Login from './pages/loginAuth';
import Privacy from './pages/privacy';
import Agreement from './pages/agreement';
import NotFound from './pages/404';
import { DeepGuardProvider, ProductSource, deepGuardConfig } from './deep-auth';
// import { ConfigProvider } from 'antd';

deepGuardConfig.init({
  source: ProductSource.DeepChat,
  appId: '63896af8f11073762bd47e9e',
  config: {
    privacyPolicyUrl: '/privacy',
    userProtocolUrl: '/agreement',
    copyrightUrl: '',
  },
});

const App = () => {
  const contextValue = useCreateReducer<HomeInitialState>({
    initialState,
  });

  const {
    state: { apiKey, lightMode, folders, sessions, prompts, userId },
    dispatch,
  } = contextValue;

  // 创建一个新的对话/聊天
  const handleNewSession = async () => {
    const result: any = await reqNewSession(userId);
    if (result.status === 0) {
      toast.success('新建会话成功！');
      const newSession: Session = {
        sessionId: result.sessionId,
        sessionTitle: '未命名',
        // action: '_ask1',
        // model: 'dlm-1',
        // meta: {
        //   id: uuidv4(),
        //   content: {
        //     content_type: 'text',
        //     session: [],
        //     parts: []
        //   }
        // }
      };

      // 插入到对话列表中
      const updatedSessions = [...sessions, newSession];

      dispatch({ field: 'selectedSession', value: newSession }); // 被选中的对话
      dispatch({ field: 'sessions', value: updatedSessions }); // 全部的对话列表

      // 存储被选中的对话和对话列表
      // saveConversation(newConversation);
      // saveConversations(updatedConversations);
      saveSession(newSession);

      dispatch({ field: 'loading', value: false });
    } else {
      toast.error('新建会话失败！');
    }
  };

  // -------------------------- 文件夹操作 --------------------------

  const handleCreateFolder = (name: string, type: FolderType) => {
    const newFolder: FolderInterface = {
      id: uuidv4(),
      name,
      type,
    };

    const updatedFolders = [...folders, newFolder];

    dispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);
  };

  const handleDeleteFolder = (folderId: string) => {
    const updatedFolders = folders.filter((f) => f.id !== folderId);
    dispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);

    const updatedSessions: Session[] = sessions.map((s) => {
      // if (s.folderId === folderId) {
      //   return {
      //     ...s,
      //     folderId: null,
      //   };
      // }

      return s;
    });

    dispatch({ field: 'sessions', value: updatedSessions });
    saveSessions(updatedSessions);

    const updatedPrompts: Prompt[] = prompts.map((p) => {
      if (p.folderId === folderId) {
        return {
          ...p,
          folderId: null,
        };
      }

      return p;
    });

    dispatch({ field: 'prompts', value: updatedPrompts });
    savePrompts(updatedPrompts);
  };

  const handleUpdateFolder = (folderId: string, name: string) => {
    const updatedFolders = folders.map((f) => {
      if (f.id === folderId) {
        return {
          ...f,
          name,
        };
      }

      return f;
    });

    dispatch({ field: 'folders', value: updatedFolders });

    saveFolders(updatedFolders);
  };

  // -------------------------- 会话操作 --------------------------
  const handleUpdateSession = async (renameValue: string, session: Session | undefined) => {
    const result: any = await reqUpdateSession(renameValue, session?.sessionId);
    if (result.status === 0) {
      // message.success('修改会话名称成功！');
      const updatedSession = {
        ...session,
        sessionTitle: renameValue,
      };
      dispatch({
        field: 'selectedSession',
        value: updatedSession,
      });
      saveSession(updatedSession);
    } else {
      // message.error('修改会话名称失败！');
    }
  };

  // -------------------------- 模型获取 --------------------------
  const handleSelectSession = async (session: Session) => {
    dispatch({
      field: 'selectedSession',
      value: session,
    });
    await saveSession(session);
  };

  return (
    <DeepGuardProvider
      source={ProductSource.DeepChat}
      appId="63896af8f11073762bd47e9e"
      mode="modal"
    >
      {/* <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#2d2d2d',
          },
        }}
      > */}
      <HomeContext.Provider
        value={{
          ...contextValue,
          handleNewSession,
          handleCreateFolder,
          handleDeleteFolder,
          handleUpdateFolder,
          handleSelectSession,
          handleUpdateSession,
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<h2>Loading...</h2>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/agreement" element={<Agreement />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Toaster />
        </BrowserRouter>
      </HomeContext.Provider>
      {/* </ConfigProvider> */}
    </DeepGuardProvider>
  );
};

export default App;
