/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-30 10:22:04
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-06-30 13:19:30
 * @FilePath: /deep-chat/src/components/Chat/ChatLoader.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { FC } from 'react';
import DeepChat from '../../../assets/images/DeepLang.jpg';

interface Props { }

export const ChatLoader: FC<Props> = () => {
  return (
    <div
      className="group border-b border-black/10 bg-gray-50 text-gray-800 dark:border-gray-900/50 dark:bg-[#444654] dark:text-gray-100"
      style={{ overflowWrap: 'anywhere' }}
    >
      <div className="m-auto flex gap-4 p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-2xl lg:px-0 xl:max-w-3xl">
        <div className="min-w-[40px] items-end">
          {/* <IconRobot size={30} /> */}
          <img alt="Deep Chat" src={DeepChat} style={{ width: 30, borderRadius: 5 }} className="animate-spin" />
        </div>
        <span className="animate-pulse cursor-default mt-1">▍</span>
      </div>
    </div>
  );
};
