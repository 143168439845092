import CryptoJS from 'crypto-js';

// TODO: 自动设置环境变量
// qa环境
// 域名：qa-api-passport.shenyandayi.com
const qa = {
  aeskey: 'deep#$Auth2024$!',
  aesiv: 'iv!@deep$2024##',
};
// pre环境
// 域名：pre-api-passport.shenyandayi.com
const pre = {
  aeskey: 'BN$Lz=rN5eh*jw&6hu4@tsBKLaARCZnv',
  aesiv: 'yzQXHB=wTD6=Bv=fDgNpykWGCGwNB454',
};
// prod环境
// 域名：api-passport.shenyandayi.com
const prod = {
  aeskey: 'BN$Lz=rN5eh*jw&6hu4@tsBKLaARCZnv',
  aesiv: 'yzQXHB=wTD6=Bv=fDgNpykWGCGwNB454',
};

let envAes = qa;
// 环境的切换
if (process.env.NODE_ENV === 'development') {
  envAes = qa;
} else if (window.location.href.includes('qa')) {
  envAes = qa;
} else if (window.location.href.includes('pre')) {
  envAes = pre;
} else {
  envAes = prod;
}

export const encrypt = (text: string) => {
  const key = CryptoJS.enc.Utf8.parse(envAes.aeskey);
  const iv = CryptoJS.enc.Utf8.parse(envAes.aesiv);
  const srcs = CryptoJS.enc.Utf8.parse(text);

  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decrypt = (text: string) => {
  const key = CryptoJS.enc.Utf8.parse(envAes.aeskey);
  const iv = CryptoJS.enc.Utf8.parse(envAes.aesiv);

  const decrypted = CryptoJS.AES.decrypt(text, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  console.log('decrypted-------', decrypted.toString(CryptoJS.enc.Utf8));
  return decrypted.toString(CryptoJS.enc.Utf8);
};
