/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-21 14:05:05
 * @FilePath: /deep-chat/components/Chatbar/components/ChatbarSettings.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  IconLogout,
  IconSettings,
  IconTriangleInvertedFilled,
  IconAlertTriangle,
  IconX,
} from '@tabler/icons-react';
import { useContext, useState } from 'react';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import { useGuard } from '@authing/guard-react18';

import HomeContext from '../../../pages/home/home.context';
import { reqFeedbackMessage } from '../../../api';
import { SettingDialog } from '../../../components/Settings/SettingDialog';

import { SidebarButton } from '../../Sidebar/SidebarButton';
import ChatbarContext from '../Chatbar.context';
import { ClearConversations } from './ClearConversations';

import { Toaster, toast } from 'react-hot-toast';
import User from '../../../../assets/images/user.png';
import '../../../styles/message.css';
import { ModalShowType, useDeepGuard, DeepLoginModal } from '../../../deep-auth';

export const ChatbarSettings = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem('username');
  const [isSettingDialogOpen, setIsSettingDialog] = useState<boolean>(false);
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const deepGuard = useDeepGuard();
  const [showFeekback, setShowFeedback] = useState<boolean>(false);
  const [feedbackContent, setFeedbackContent] = useState<string>('');
  const [isUnSafe, setIsUnSafe] = useState<boolean>(false);
  const [isUseless, setIsUseless] = useState<boolean>(false);
  const [isNotTrue, setIsNotTrue] = useState<boolean>(false);
  const [thumbDown, setThumbDown] = useState<boolean>(false);

  const {
    state: { conversations },
  } = useContext(HomeContext);

  const { handleClearSessions, handleExportData } = useContext(ChatbarContext);

  const handleCloseFeedback = () => {
    setShowFeedback(false);
    setIsUnSafe(false);
    setIsUseless(false);
    setIsNotTrue(false);
    setFeedbackContent('');
  };
  const handleUpdateFeedbackContent = (e: any) => {
    setFeedbackContent(e.target.value);
  };
  const handleCheckout = (e: any) => {
    const value = e.target.value;
    if (value === 'isUnSafe') {
      setIsUnSafe(!isUnSafe);
    } else if (value === 'isUseless') {
      setIsUseless(!isUseless);
    } else {
      setIsNotTrue(!isNotTrue);
    }
  };
  const handleFeedback = async () => {
    const feedback = {
      chatId: '-1',
      feedbackContent,
      isUnSafe,
      isUseless,
      isNotTrue,
    };
    const result: any = await reqFeedbackMessage(feedback);
    if (result.status === 0) {
      toast.success('提交成功！感谢您的反馈！', {
        duration: 60000,
      });
      setThumbDown(!thumbDown);
    } else {
      toast.error('反馈失败，请再试一次...');
    }
    setShowFeedback(false);
  };
  // 退出登录
  const handleLogout = () => {
    deepGuard.logout();
    navigate('/login');
  };

  return (
    <div className="flex flex-col items-center space-y-1 border-t border-white/20 pt-1 text-sm">
      {conversations.length > 0 ? (
        <ClearConversations onClearConversations={handleClearSessions} />
      ) : null}

      {/* 导入对话 */}
      {/* <Import onImport={handleImportConversations} /> */}

      {/* 导出对话 */}
      {/* <SidebarButton
        text='导出会话'
        icon={<IconFileExport size={18} />}
        onClick={() => handleExportData()}
      /> */}

      <SidebarButton
        text={'设置'}
        icon={<IconSettings size={18} />}
        onClick={() => setIsSettingDialog(true)}
      />
      <SidebarButton
        text={'反馈'}
        icon={<IconAlertTriangle size={18} />}
        onClick={() => setShowFeedback(true)}
      />

      <div className="user-info" onClick={() => setShowLogout(!showLogout)}>
        <img alt={username || 'avatar'} src={User} />
        {username}
      </div>
      {showLogout && (
        <div className="log-out" onClick={handleLogout}>
          <IconLogout className="log-out-icon" />
          退出登录
          <IconTriangleInvertedFilled className="triangle-icon" />
        </div>
      )}

      <SettingDialog
        open={isSettingDialogOpen}
        onClose={() => {
          setIsSettingDialog(false);
        }}
        onDestory={() => {
          setIsSettingDialog(false);
          deepGuard.show(ModalShowType.AuthenticatedDestroy);
        }}
      />
      <div
        className={`smegma-content ${showFeekback ? '' : 'smegma-content-hidden'}`}
        onClick={handleCloseFeedback}
      ></div>
      <div
        className={`feedback-modal light-style-dark ${showFeekback ? '' : 'feedback-modal-hidden'}`}
      >
        <h1 className="feedback-title text-white">
          <IconAlertTriangle size={20} />
          提交反馈
        </h1>
        <button className="close-btn" onClick={handleCloseFeedback}>
          <IconX size={20} />
        </button>
        <div className="feedback-content">
          <textarea
            className="feedback-input"
            name="feedback"
            placeholder="请输入您的意见与反馈"
            cols={45}
            rows={4}
            value={feedbackContent}
            onChange={handleUpdateFeedbackContent}
          />
          <div>
            <input
              className="feedback-select"
              type="checkbox"
              color="#444653"
              value="isUnSafe"
              onChange={handleCheckout}
            />{' '}
            包含有害内容
          </div>
          <div>
            <input
              className="feedback-select"
              type="checkbox"
              value="isNotTrue"
              onChange={handleCheckout}
            />{' '}
            内容不正确
          </div>
          <div>
            <input
              className="feedback-select"
              type="checkbox"
              value="isUseless"
              onChange={handleCheckout}
            />{' '}
            没有帮助
          </div>
        </div>
        <button className="feedback-submit" onClick={handleFeedback}>
          提交反馈
        </button>
      </div>
      <DeepLoginModal />
    </div>
  );
};
