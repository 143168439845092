interface IStorage {
  set(key: string, value: any): void;
  get(key: string): any;
  remove(key: string): void;
}

// 判断是否是插件环境,TODO: 不同浏览器的判断
// const isExtension = typeof chrome !== 'undefined' && chrome?.storage;

class Storage {
  set(key: string, value: string) {
    // if (isExtension) {
    //   chrome?.storage.local.set({ [key]: value });
    // }
    localStorage.setItem(key, value);
  }

  get(key: string) {
    // if (isExtension) {
    //   return new Promise((resolve) => {
    //     chrome?.storage.local.get([key], (result: any) => {
    //       resolve(result[key] || '');
    //     });
    //   });
    // }
    const value = localStorage.getItem(key);
    return value;
  }

  remove(key: string) {
    localStorage.removeItem(key);
    // if (isExtension) {
    //   chrome?.storage.local.remove([key]);
    // }
  }
}

const storage: IStorage = new Storage();

export default storage;
