// import apiDebugMap from '@/config/apiDebugMap';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { removeAuthInfo, setAuthInfo, setHeadersAuthInfo } from './index';
import to from './await-to';
import { EventKeys } from './pubsub';
import api from '../api/user';

export enum ERROR_CODES {
  SUCCESS = 0, // 成功
  TOKEN_NOT_FOUND = 986, // 不存在 token
  AUTH_TOKEN_EXPIRED = 987, // auth-token 过期
  ACCESS_TOKEN_EXPIRED = 988, // access-token 过期
  LOGIN_EXPIRED = 995, // 登录过期
}

export interface ApiResponse<T> {
  code: number;
  msg: string;
  data: T;
}

let baseURL;
// 环境的切换
if (process.env.NODE_ENV === 'development') {
  baseURL = 'https://qa-api-passport.shenyandayi.com';
} else if (window.location.href.includes('qa')) {
  baseURL = 'https://qa-api-passport.shenyandayi.com';
} else if (window.location.href.includes('pre')) {
  baseURL = 'https://pre-api-passport.shenyandayi.com';
} else {
  baseURL = 'https://api-passport.shenyandayi.com';
}

const service = axios.create({
  baseURL,
  timeout: 5000,
});

service.interceptors.request.use(
  async (config) => {
    await setHeadersAuthInfo(config.headers);
    return config;
  },
  (error) => Promise.reject(error),
);

service.interceptors.response.use(
  async (response: AxiosResponse<ApiResponse<any>>) => {
    const { status, data, config } = response as any;

    // Token 失效, 刷新token后重新请求
    if (data.code === ERROR_CODES.ACCESS_TOKEN_EXPIRED && !config?.hasRefresh) {
      const result = await refreshToken(config, service);
      console.log('result====1', result);
      return result;
    }

    // 登录过期
    if (data.code === ERROR_CODES.LOGIN_EXPIRED) {
      removeAuthInfo();
      PubSub.publish(EventKeys.LOGOUT);
      return Promise.reject(data);
    }

    if (status !== 200 || data.code !== 0) {
      return Promise.reject(data);
    }

    return Promise.resolve(data.data);
  },
  (err) => {
    return Promise.reject(err);
  },
);

export async function refreshToken(config: any, service: AxiosInstance) {
  const [err1, result1] = await to(api.refreshToken());
  if (err1) {
    removeAuthInfo();
    return Promise.reject(err1);
  }
  setAuthInfo(result1);
  config.hasRefresh = true;
  const [err2, result2] = await to<any>(service(config));
  if (err2) {
    // removeAuthInfo();
    return Promise.reject(err2);
  }
  return result2;
}

export default service;
