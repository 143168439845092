/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-21 16:34:23
 * @FilePath: /deep-chat/utils/app/conversation.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import cookie from 'react-cookies';
import { reqConversationDetail } from '../../api';
import { Conversation } from '../../types/chat';

const userId = cookie.load('userId');

export const getConversation = async (sessionId: any) => {
  if (sessionId) {
    const { headers, data }: any = await reqConversationDetail(sessionId, userId);
    if (data.status === 0) {
      return {
        sensitive: !!headers.sensitive,
        result: data.chatList
      };
    }
  }
  return {
    sensitive: false,
    result: []
  };
};

export const updateConversation = (
  updatedConversation: Conversation,
  allConversations: Conversation[],
) => {
  // 获取更新后的会话列表
  const updatedConversations = allConversations.map((c) => {
    if (c.chatList === updatedConversation.chatList) {
      return updatedConversation;
    }
    return c;
  });

  // 保存新的会话
  saveConversation(updatedConversation);
  saveConversations(updatedConversations);

  return {
    single: updatedConversation,
    all: updatedConversations,
  };
};

export const saveConversation = (conversation: Conversation | undefined) => {
  if (conversation) {
    localStorage.setItem('selectedConversation', JSON.stringify(conversation));
  } else {
    localStorage.removeItem('selectedConversation');
  }
};

export const saveConversations = (conversations: Conversation[]) => {
  localStorage.setItem('conversationHistory', JSON.stringify(conversations));
};
