/*
 * @Author: Lemon.Feng
 * @Date: 2023-06-30 00:17:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-06-30 13:51:12
 * @FilePath: /deep-lang-new/src/components/Mobile/Navbar.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { IconPlus } from '@tabler/icons-react';
import { FC } from 'react';

import { Session } from '../../types/chat';

interface Props {
  selectedSession: Session | undefined;
  onNewSession: () => void;
}

export const Navbar: FC<Props> = ({ selectedSession, onNewSession }) => {
  return (
    <nav className="flex w-full justify-between bg-[#202123] py-3 px-4">
      <div className="mr-4"></div>

      <div className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap">
        {selectedSession ? selectedSession.sessionTitle : ''}
      </div>

      <IconPlus className="cursor-pointer hover:text-neutral-400 mr-8" onClick={onNewSession} />
    </nav>
  );
};
