import { defaultUserInfo } from './useShare';
import { IUpdatePwd, Options, IUseUserReturn, IUseShareReturn } from '../type';
import apiUser from '../api/user';
import to from '../utils/await-to';
import { encrypt } from '../utils/encrypt';
import { getPrefixKeys, removeAuthInfo } from '../utils';
import { MobileActions, PasswordActions } from '../utils/constants';
import { EventKeys } from '../utils/pubsub';
import storage from '../utils/storage';

interface IProps extends Options, IUseShareReturn {}

function useUser(props: IProps): IUseUserReturn {
  const { logout, state, updateState } = props;

  const updateNickname = async (text: string) => {
    const data = { user_name: text };
    const [err, result] = await to(apiUser.updateUserInfo(data));
    if (err) return Promise.reject(err);
    updateState({ userInfo: { ...state.userInfo, user_name: text } });
    return result;
  };

  const getUserinfo = async () => {
    updateState({loading: true})
    const [err, result] = await to(apiUser.getUserinfo());
    if (err) {
      updateState({loading: false})
      return Promise.reject(err);
    }
    const { bid, accessToken, authToken } = getPrefixKeys();
    const data = await Promise.all([
      storage.get(bid),
      storage.get(authToken),
      storage.get(accessToken),
    ]);
    result.b_id = data[0];
    result.auth_token = data[1];
    result.access_token = data[2];
    updateState({ userInfo: result, hadLogin: true });
    return result;
  };

  const checkUser = async (num: string) => {
    const phone = encrypt(num);
    const [err, result] = await to(apiUser.checkUserPhone({ phone }));
    if (err) {
      return Promise.reject(err);
    }
    return result;
  };

  const updatePassword = async (params: IUpdatePwd) => {
    const { newPwd, oldPwd, action } = params;
    const data: any = { new_pwd: encrypt(newPwd) };
    if (action === PasswordActions.UpdatePwd) {
      data.action = MobileActions.UpdatePwd;
    } else if (action === PasswordActions.ResetPwd) {
      data.action = MobileActions.ResetPwd;
    } else {
      data.action = MobileActions.SettingPwd;
    }

    if (oldPwd) data.old_pwd = encrypt(oldPwd);

    const [err, result] = await to(apiUser.updatePwd(data));
    if (err) return Promise.reject(err);
    if (action !== PasswordActions.SettingPwd) {
      logout();
      PubSub.publish(EventKeys.UPDATE_PASSWORD, {
        action: PasswordActions.UpdatePwd,
        data: result,
      });
    } else {
      getUserinfo();
      PubSub.publish(EventKeys.UPDATE_PASSWORD, {
        action: PasswordActions.SettingPwd,
        data: result,
      });
    }
    return result;
  };

  const destroy = async () => {
    const [err, result] = await to(apiUser.destroy());
    if (err) return Promise.reject(err);
    updateState({ userInfo: { ...defaultUserInfo }, hadLogin: false });
    removeAuthInfo();
    return result;
  };

  const refreshToken = async () => {
    const [err, result] = await to(apiUser.refreshToken());
    if (err) return Promise.reject(err);
    return result;
  };

  return { getUserinfo, updatePassword, updateNickname, checkUser, destroy, refreshToken };
}

export default useUser;
