import { Button, Form, Input, Space, message } from 'antd';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash-es';
import styles from '../style/index.module.css';
import { MobileActions, errorText, useDeepGuard } from '../../index';
import to from '../../utils/await-to';
import useCountdown from '../../hooks/useCountDown';

type FieldType = {
  phone?: string;
  phoneCode?: string;
};

const MobileUpdate = () => {
  const deepGuard = useDeepGuard();
  const [form] = Form.useForm();

  const { second, start, reset, isCounting } = useCountdown(60, () => {
    reset();
  });

  const verificationCodeBtnText = useMemo(() => {
    if (isCounting) {
      return `${second}秒`;
    }
    return '获取验证码';
  }, [isCounting, second]);

  const onFinish = async (values: any) => {
    const { phone, phoneCode } = values;
    const [err] = await to(
      deepGuard.updateMobile({
        phone,
        code: phoneCode,
      }),
    );
    if (err) {
      const name = err.code === 206 ? 'phone' : 'phoneCode';
      form.setFields([{ name: name, errors: [errorText(err.code)] }]);
      return;
    }
    message.success('修改成功');
    deepGuard.hide();
    deepGuard.getUserinfo();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleGetCode = useCallback(
    debounce(async () => {
      getCode();
    }, 500),
    [],
  );

  const getCode = async () => {
    const phoneNumber = await form.validateFields(['phone']);
    if (phoneNumber.phone === '') {
      form.setFields([{ name: 'phone', errors: ['请输入手机号'] }]);
      return;
    }
    const [err] = await to(
      deepGuard.sendMobileCode({
        phone: phoneNumber.phone,
        action: MobileActions.UpdateMobile,
      }),
    );

    if (err) {
      message.error(err.msg);
      return;
    }
    start();
  };
  return (
    <div className={cx(styles.pr16, styles.pl16)} style={{ width: ' 292px' }}>
      <h3 className={styles.title}>绑定新手机号</h3>
      <Form
        name="newPhoneForm"
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          className={styles.formItemContent}
          name="phone"
          rules={[
            { required: true, message: '请输入手机号' },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确的手机号',
            },
          ]}
        >
          <Input placeholder="请输入手机号" allowClear />
        </Form.Item>
        <Form.Item
          name="phoneCodeGroup"
          wrapperCol={{ span: 24 }}
          className={cx(styles.mb0, styles.formItemContent)}
        >
          <Space align="start">
            <Form.Item name="phoneCode" rules={[{ required: true, message: '请输入验证码' }]}>
              <Input name="phoneCode" placeholder="请输入验证码" allowClear />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                disabled={isCounting}
                className={cx({
                  [styles.btnDisabled]: isCounting,
                })}
                onClick={() => handleGetCode()}
              >
                {verificationCodeBtnText}
              </Button>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} className={styles.formItemContent}>
          <Button type="primary" className={cx(styles.fullWidth)} htmlType="submit">
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MobileUpdate;
