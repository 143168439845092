import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Logo from '../../../assets/images/logo.png';

import './index.less';

function Agreement() {
  return (
    <div className="protocal">
      <div className='container mx-auto mb-10'>
        <Link to="/">
          <img src={Logo} alt="logo" width={120} className="-ml-4" />
        </Link>
        <h2 className="text-lg mb-4">语鲸大模型服务协议</h2>
        <p>在使用语鲸大模型服务（本服务）之前，请认真阅读语鲸大模型服务服务协议。</p>
        <p>语鲸大模型服务协议（以下简称“协议”、“服务协议”）将为您介绍在邀测期间使用本服务时所享有的权利、应履行的义务和责任。<span>请您认真阅读、充分理解各条款内容，特别是免除责任、责任限制等可能对您权益造成影响的条款，同时我们已为您以粗体字的形式予以提示</span>。</p>
        <p><span>当您测试使用本服务时，即表示您已阅读、充分理解并同意接受本协议的全部内容，本协议即对您产生法律约束力。如您不同意部分或全部协议条款的，请您停止使用本服务</span>。</p>
        <p>本协议由北京深言科技有限责任公司（“我们”、“深言”）与您所代表的实体（“您”、“用户”）签订，您向我们声明并保证，您具有合法代表该实体之权限且有可约束该实体遵守本协议条款与条件之合法权限。除非深言另有通知，本协议自您线上点击签署之日起生效。您与深言在下文中单独提及时会被称作一方，一同提及时会被称作双方。</p>
        <p>在点击签署本协议时，您确认已在网站线上点击签署《用户注册与服务协议》、《语鲸大模型隐私协议》。本协议自您点击签署之时生效。本协议是前期线上协议不可分割的的组成部分，若本协议与前期线上协议不一致之处，以本协议为准。本协议未约定的内容，以前期线上协议为准。</p>
        <dl>
          <dt>一、服务使用</dt>
          <dl>
            <dt>（一）服务内容</dt>
            <dd>
              本产品是一个全功能的大模型产品，拥有强大的语言处理和智能推理能力，提供的服务包括文本生成、智能问答、自然语言处理等AI产品服务。产品具备用户友好的界面和直观的操作方式，使用户能够轻松地利用其功能进行各种任务。
              我们承诺对用户的数据进行安全存储和保护。用户上传的数据将被加密存储，并采取适当的技术措施防止未经授权的访问、使用或泄露。我们将按照隐私政策中所述的规定处理用户的数据，并且不会与任何第三方分享用户数据，除非事先得到用户的明确授权或法律要求。
              为了提供更好的用户体验和功能改进，我们将定期对产品进行升级和更新。升级和更新可能涉及系统中断或用户操作的影响。我们将提前通知用户升级和更新的计划，并在可能的情况下为用户提供操作指南和培训，以确保用户能够顺利适应新版本的产品。
              我们的产品可能集成一些第三方服务或功能，以扩展产品的功能和提供更多的选择。在使用这些第三方服务或功能之前，用户需要了解并同意额外的条款和条件，这些条款和条件可能由第三方服务提供商制定。我们将尽力向用户提供相关信息和链接，以便用户了解和遵守这些条款和条件。
              深言保留新增服务，变更、中止或终止提供以上服务的权利。
            </dd>
            <dt>（二）服务形式</dt>
            <dd>1. 在线服务形式</dd>
            <dd>本产品以在线服务形式提供，用户通过互联网连接访问产品平台，无需安装额外的软件或硬件设备。用户可以通过电脑或手机端的浏览器访问产品，并享受产品提供的功能和服务。</dd>
            <dd>2. 云服务形式</dd>
            <dd>本产品基于云计算技术提供服务，用户的数据和操作结果存储在云服务器上，用户可以随时随地通过互联网访问和管理自己的数据和操作记录。云服务形式具有高可靠性、可扩展性和灵活性等优势，为用户提供便捷的使用体验。</dd>
            <dt>（三）权利授予</dt>
            <dd>深言授权用户非商业性的、有限的、非独占性的使用权，允许用户使用产品的功能和服务。如果要进行商业性使用（包括但不限于：销售、复制、散发、预装、捆绑或用于其他能给您带来任何形式的商业利益的活动）时，必须事先获得我们的书面授权和许可。</dd>
          </dl>
          <dt>二、使用限制与免责声明</dt>
          <dd>在使用本产品时，您不得：</dd>
          <dd>1. 对本服务项下的大模型进行渗透，以窃取语鲸大模型及其相关模型、数据等资产或反向汇编、反编译、翻译或以其他方式尝试发现本服务的模型、组件、算法、系统或其他计算机程序的源代码或参数;</dd>
          <dd>2. 未经深言授权，使用本服务和/或本服务的“输出内容”开发、训练本服务以外的模型;</dd>
          <dd>3. 使用任何自动或编程方法从本服务中提取数据或“输出内容”，包括爬虫、抓取、网络收集或网络数据提取;</dd>
          <dd>4. 使第三方误认为本服务的“输出内容”是人为生成;</dd>
          <dd>5. 未经我们事先书面同意，出售、转售或转让本服务;</dd>
          <dd>6. 向我们发送或输入任何违反法律法规、侵犯知识产权与第三方合法权益、侵犯他人身心健康或任何可能给深言带来风险的数据（如具有歧视性、敏感性数据）；</dd>
          <dd>7. 向我们发送或输入您的或者任何第三方的商业秘密或具有商业权益的数据；</dd >
          <dd>8. 使用本服务侵犯、盗用或违反任何人权利（权益），或从事任何违反法律法规的行为，包括不限于进行网络犯罪、生成虚假信息、侵犯第三方权益（包括但不限于著作权、专利权、商标权、肖像权等）、商业秘密等，利用算法、数据、平台等优势实施不公平竞争；</dd >
          <dd>9. 向我们发送或输入任何含有木马、病毒、恶意软件或任何其他有可能影响本服务安全及稳定性的内容、不得对本服务采取任何攻击手段包含但不限于诱导性的提问，或利用本服务的功能去攻击其它服务、机构等；</dd >
          <dd>10. 具有任何可能给我们带来潜在风险或损失的行为（包括作为或不作为）。</dd >
          <p>您理解并同意，在您使用本服务时，您应当对“您的输入”是否符合法律法规的相关规定、本协议约定及其他要素进行审查，以确保“您的输入”不会给深言带来任何潜在风险或损失，如我们认为“您的输入”不符合本条约定，我们有权独自决定并立即：①删除“您的输入”（如您输入的图像、文本）；②暂停、停止为您提供本服务。如深言或第三方因“您的输入”而遭受损失的，由您承担相关责任。</p>
          <p>您理解并同意，在您使用本服务时，如发现本服务的“输出内容”不符合法律法规、“可接受的使用政策”或出现本协议第7条所述场景、以及任何可能给深言带来负面影响的内容，您应依照本协议第7条及时联系我们，但您不能未经深言书面授权将上述“输出内容”或相关信息向第三方披露。</p>
          <p>您承诺测试本服务仅用于内部测试使用，不会使用本服务用于其他任何目的，包括向任何第三方提供、出售、转让本服务或者允许第三方使用本服务。</p>
          <dl>
            <dt>三、内容与所有权</dt>
            <dd>对于您输入的“提示词”，您应当确保您依据法律规定且有相关的合法权利或授权使得您有权将所述“提示词”输入给本服务，并就所述“提示词”授予深言永久的、不可撤销的、免费的、非独占的许可以供深言支撑、运行本服务。“提示词”指在您使用「文本生成」功能时输入的内容。您应当承担您输入所述“提示词”所产生的一切责任。</dd>
            <dd>本服务的「文本生成」功能是根据您输入的“提示词”产生“输出内容”，您理解并同意，上述“输出内容”是本服务根据您输入的“提示词”计算得到的结果，您对“输出内容”具有实质性的掌控力，应当视为您实际创作、生成了所述“输出内容”，您是上述“输出内容”的权利人，您可以依照法律法规自行决定如何使用上述“输出内容”，并应当对上述“输出内容”的使用承担责任，深言不会对您的上述使用行为承担任何责任。您承诺，未经深言书面同意，您应当确保您在使用上述“输出内容”时，不会向任何第三方以任何方式明示或暗示所述“输出内容”与本服务或深言具有任何形式的关联性，包括明示或暗示所述“输出内容”是由本服务生成或依赖了本服务提供的能力等。</dd>
            <dd>内容的不确定性和非唯一性。由于机器学习的性质，本服务的“输出内容”具有不确定性和非唯一性，基于不同场景或不同用户，本服务“输出内容”可能相同或近似也可能存在差异。即使其他用户获得的“输出内容”与您的“输出内容”构成相同或近似，其他用户的“输出内容”也不应视为您的内容。</dd>
            <dd>使用内容改善服务。我们可能依照相关法律法规要求或本协议第7条约定，使用“您的输入”对本服务进行开发改进。除上述情况外，我们不会使用“您的输入”来开发或改进本服务。</dd>
            <dd>准确性、完整性、真实性、可靠性与实用性。您了解并同意，机器学习和人工智能的研究领域处于快速发展阶段，深言致力于不断努力和改进我们的服务，使其更加安全、准确和可靠。受限于当前行业研究水平、人工智能服务的概率特性并结合“您的输入”信息或需求的详细程度或特定性，本服务的“输出内容”可能在某些情况下不准确、不完整、不真实、不可靠或不实用。</dd>
            <dd>您应对本服务“输出内容”的准确性、完整性、真实性、可靠性与实用性自行加以判断，如人工审核。您应确保在依照本服务任何“输出内容”做出决策前都是经过您的确认。您应承担因使用或者依赖本服务“输出内容”而引起的所有风险，包括因对内容的合法性、完整性、真实性、可靠性或实用性的依赖而产生的风险。深言无法且不会对因前述风险而导致的任何损失或损害承担责任。</dd>
            <dd>我们对本服务“输出内容”的准确性、完整性、真实性、可靠性或实用性不做任何保证，亦不承担任何法律责任。本服务的“输出内容”均不代表深言的态度、观点或立场。</dd>
          </dl>
          <dl>
            <dt>四、安全和数据隐私</dt>
            <dd>您承诺“您的输入”不包括任何违反本协议第1条的内容或数据。</dd>
            <dd>个人信息的处理。作为服务的一部分，为了保护您向我们提供的隐私信息，您需要遵守深言隐私政策声明。</dd>
            <dd>对“您的输入”数据的处理。您承诺“您的输入”不会有任何个人信息。若“您的输入”含有个人信息（含未成年人个人信息等个人敏感数据），您应对输入的个人信息进行匿名化处理。</dd>
            <dd>处理与保留。为向您提供本服务，您授权深言及其关联公司与供应商处理“您的输入”，我们将在【中华人民共和国境内】处理“您的输入”。为满足相关法律法规和/或监管机构要求，您授权我们保留“您的输入”，保留期限为六个月。在您上述授权的保留期到期后，且没有法律法规和/或监管机构要求我们继续保留“您的输入”，我们会立即删除“您的输入”。</dd>
            <dd>第三方披露。我们不会向第三方披露“您的输入”，但可能根据适用的法律法规、法律程序、诉讼/仲裁、政府的强制命令、监管要求等而向第三方披露“您的输入”，如为了满足内容合规审查要求，向相关的政府机关或为本服务提供内容合规审查的供应商披露“您的输入”。</dd>
          </dl>
          <dl>
            <dt>五、暂停与终止</dt>
            <dd>暂停。如您违反本协议、“前期线上协议”的，我们可能会暂停您测试、访问或使用服务的权利，且无须事先告知。</dd>
            <dd>终止。如您违反本协议或语鲸大模型隐私协议的约定，我们亦保留立即终止服务或本协议的权利，您在测试使用服务期间存储的内容可能会被立即删除。</dd>
          </dl>
          <dl>
            <dt>六、免责声明</dt>
            <dd>您应理解并同意，本协议项下向您提供的服务属于邀测，因此深言不对本服务的可用性、可靠性、实用性及其本服务的测试成果或者输出做出承诺，亦不对您使用本服务承担任何责任。无论何种情形发生，深言均不就本协议及其前期线上协议项下的任意行为或者场景向您承担任何责任。但深言愿意同您一同合作解决问题，并努力不断提升服务质量及服务水平。如果出现任何情况，您可拨打官网客服电话将情况告知深言，获得技术支持，如果无法解决相关问题，您应停止使用邀测试用本服务。</dd>
          </dl>
          <dl>
            <dt>七、投诉举报</dt>
            <dd>如果您发现本服务或本服务“输出内容”不准确、不完整或不真实、侵犯他方知识产权、他方合法权益、或者 违反相关法律法规、商业道德的或任何可能给您或深言带来负面影响的内容，如您希望我们予以更正，您可以通过反馈邮箱 feedback@deeplang.ai 联系我们提交更正请求。鉴于模型工作方式的技术复杂性，我们可能无法纠正每个实例，针对该问题，签署本协议视为您已知悉并同意。</dd>
            <dd>如果我们发现或收到他人举报或投诉您者我们合理判断认为您违反本协议、前期线上协议约定或法律法规的，深言有权不经通知随时对相关内容进行删除，并视行为情节处以包括但不限于警示、限制或禁止使用本服务部分或全部功能、冻结账号直至注销、暂停或者终止其他深言服务的处罚，并公告处理结果。另外我们依法有权保存有关记录，并向有关主管部门报告并公告处理结果。如我们因此遭受损失的，您应当承担相应责任。</dd>
          </dl>
          <dl>
            <dt>八、保密</dt>
            <dd>除本协议的条款外，双方同意遵守“语鲸大模型隐私协议”。该保密协议通过援引成为本协议的一部分。</dd>
          </dl>
          <dl>
            <dt>九、定义</dt>
            <dd>“您的输入”，指本协议3.1中定义的“提示词”。</dd>
            <dd>“输出内容”，指本协议3.3条中定义“输出内容”。</dd>
            <dd>“大模型”，指由具有上亿个参数的神经网络组成，使用自监督学习或半监督学习对大量未标记数据进行训练的模型。完成训练的大模型，可以对一般化的输入返回较好地推理结果。</dd>
            <dd>“个人信息处理者”，指《中华人民共和国个人信息保护法》中定义的个人信息处理者。</dd>
          </dl>
          <dl>
            <dt>十、其他</dt>
            <dd>您使用本服务即视为您已阅读并同意受本协议的约束。深言有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的条款。本协议条款变更后，如果您继续使用服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。</dd>
            <dd>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律。</dd>
            <dd>若您和深言之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交深言公司所在地有管辖权的人民法院管辖。</dd>
          </dl>
        </dl>
      </div>
      <Footer />
    </div>
  );
}

export default Agreement;
