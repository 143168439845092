import { useEffect, useRef, useState } from 'react';
const UINT_TIME = 1000;
/**
 * countdown hook
 *
 * @param value - initial second number
 * @param onStop - trigger onStop when countdown ends
 * @returns - second: remaining seconds
 *          - isCounting: countdown in progress
 *          - start: start countdown
 *          - reset: reset countdown
 */

const useCountdown = (
  value: number,
  onStop?: () => void,
): {
  second: number;
  isCounting: boolean;
  start: () => void;
  reset: () => void;
} => {
  const [second, setSecond] = useState(value);
  const [isCounting, setIsCounting] = useState(false);
  const flag = useRef(0);
  const timeCallBack = useRef<() => void>();
  const isStart = useRef(false);
  // use ref to avoid closure error
  timeCallBack.current = () => {
    setSecond((prevSecond) => prevSecond - 1);
    if (second - 1 <= 0) {
      isStart.current = false;
      setIsCounting(false);
      onStop?.();
      clearInterval(flag.current);
      return;
    }
  };
  const start = (): void => {
    if (!isStart.current) {
      isStart.current = true;
      setIsCounting(true);
      flag.current = window.setInterval(() => {
        timeCallBack.current?.();
      }, UINT_TIME);
    }
  };
  const reset = (): void => {
    clearInterval(flag.current);
    isStart.current = false;
    setIsCounting(false);
    setSecond(value);
  };
  useEffect(() => {
    return () => clearInterval(flag.current);
  }, []);
  return {
    second,
    isCounting,
    start,
    reset,
  };
};

export default useCountdown;
