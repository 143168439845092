import PubSub from 'pubsub-js';
import { Options, IActionsReturn, IUseShareReturn, IState } from '../type';
import { ModalShowType } from '../utils/constants';

interface IProps extends Options, IUseShareReturn {}

function useActions(props: IProps): IActionsReturn {
  const { updateState } = props;

  const show = (options: ModalShowType | Partial<IState>) => {
    console.log('show------', options);
    let newStatus: any = {};
    if (typeof options === 'number') {
      newStatus.showType = options;
    } else {
      newStatus = options;
    }
    updateState({ ...newStatus, modalVisible: true });
  };

  const hide = () => {
    updateState({ modalVisible: false });
  };

  function on<T, U = Error>(
    event: string,
    callback: (value: [U, undefined] | [null, T]) => void,
  ): string {
    return PubSub.subscribe(event, (name, response) => {
      console.log('data', name, response);
      callback(response);
    });
  }

  function remove(tokenOrFunction: string | PubSubJS.SubscriptionListener<any>): string | boolean {
    return PubSub.unsubscribe(tokenOrFunction);
  }

  return { on, remove, show, hide };
}

export default useActions;
