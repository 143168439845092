import './index.less';
import BeianLogo from '../../../assets/images/beian.png';

function Footer() {
  return (
    <div className="footer flex flex-col">
      <p>Copyright {new Date().getFullYear()} DeepLang 版权所有</p>
      <div className="flex justify-center items-center">
        <img src={BeianLogo} alt="备案" className="w-4 h-4 mr-2" />
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802043273" rel="noreferrer" target="_blank">京公网安备11010802043273</a>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank" className="ml-4">京ICP备2022009790号-5</a>
      </div >
    </div>
  );
}

export default Footer;
