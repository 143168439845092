import { defaultUserInfo } from './useShare';
import {
  IMobileSMS,
  ILoginMobileSms,
  Options,
  IUseLoginReturn,
  IVerifyMobile,
  IUseShareReturn,
  ILoginAccount,
  ISendMobileCode,
  IUserInfo,
  IForgetPassword,
} from '../type';
import apiUser from '../api/user';
import apiLogin from '../api/login';
import to, { ApiResponseError } from '../utils/await-to';

import PubSub from '../utils/pubsub';
import { EventKeys } from '../utils/pubsub';
import { postLoginMessage, removeAuthInfo, setAuthInfo } from '../utils';
import { encrypt } from '../utils/encrypt';
import { MobileActions, ProductSource } from '../utils/constants';

interface IProps extends Options, IUseShareReturn {}

enum LoginType {
  PHONE = 'phone',
  WECHAT = 'wechat',
  ACCOUNT = 'account',
  PLUGIN = 'plugin',
}

function useLogin(props: IProps): IUseLoginReturn {
  const { source, state, updateState, logout } = props;

  // 电话号码验证：登录、注册、修改密码、修改手机号等前置验证电话号码操作
  const _mobileSms = async (params: ILoginMobileSms) => {
    const data: any = { ...params };
    data.source = source;
    data.phone = encrypt(params.phone);
    if (params.action === MobileActions.BindMobile) {
      data.open_info = state.openInfo;
    }
    const [err, result] = await to(apiLogin.mobileSms(data));
    if (err) return Promise.reject(err);
    return result;
  };

  // 第三方登录和登录成功后的操作
  const loginShare = (data = defaultUserInfo, type: string, err: ApiResponseError | null) => {
    if (err) {
      PubSub.publish(EventKeys.LOGIN_FAILED, err);
      removeAuthInfo();
      return Promise.reject(err);
    }
    updateState({ userInfo: data, hadLogin: true });
    PubSub.publish(EventKeys.LOGIN, { type, data });
    setAuthInfo(data);

    if (type !== LoginType.PLUGIN && source === ProductSource.Extension) {
      postLoginMessage('PLUGIN', data);
    }
    return data;
  };

  // 发送验证码
  const sendMobileCode = async (params: ISendMobileCode) => {
    const phone = encrypt(params.phone);
    const data = { phone, source, action: params.action || MobileActions.Login };
    const [err, result] = await to(apiLogin.sendCode(data));
    if (err) return Promise.reject(err);
    return result;
  };

  // 手机号验证码校验
  const verifyMobile = async (params: IVerifyMobile) => {
    const [err, result] = await to(_mobileSms(params));
    if (err) return Promise.reject(err);
    return result;
  };

  // 账号密码登录
  const loginAccount = async (params: ILoginAccount) => {
    const pwd = encrypt(params.pwd);
    const account = encrypt(params.account);
    const data = { account, pwd, source };
    const [err, result] = await to(apiLogin.loginAccount(data));
    return loginShare(result, LoginType.ACCOUNT, err);
  };

  // 电话号码+验证码 登录
  const loginMobile = async (params: IMobileSMS) => {
    const data = { ...params, action: MobileActions.Login };
    const [err, result] = await to(_mobileSms(data));
    return loginShare(result, LoginType.PHONE, err);
  };

  const loginWeChat = async (data: IUserInfo) => {
    return loginShare(data, LoginType.WECHAT, null);
  };

  const loginPlugin = async (data: IUserInfo) => {
    return loginShare(data, LoginType.PLUGIN, null);
  };

  // 绑定手机号
  const bindMobile = async (params: IMobileSMS) => {
    const data: any = { ...params };
    data.action = MobileActions.BindMobile;
    data.open_info = state.openInfo;
    const [err, result] = await to(_mobileSms(data));
    PubSub.publish(EventKeys.LOGIN, [err, result]);
    if (err) {
      updateState({ hadLogin: false });
      return Promise.reject(err);
    }
    updateState({ userInfo: result, hadLogin: true });
    setAuthInfo(result);
    return result;
  };

  // 修改手机号
  const updateMobile = async (params: IMobileSMS) => {
    const data: any = { ...params };
    data.action = MobileActions.UpdateMobile;
    const [err, result] = await to(_mobileSms(data));
    if (err) return Promise.reject(err);
    return result;
  };

  const loginQRCode = async () => {
    const callback_url = 'https://auth.shenyandayi.com';
    const state = encrypt(
      JSON.stringify({
        source,
        redirectUri: 'http://localhost:8089',
        env: process.env.NODE_ENV,
      }),
    );
    const data = { state: encodeURIComponent(state), callback_url };
    const [err, result] = await to(apiLogin.getQRCodeUrl(data));
    if (err) return Promise.reject(err);
    window.open(result);
    return result;
  };

  const forgetPassword = async (params: IForgetPassword) => {
    const { phone, pwd } = params;
    const data: any = {
      source,
      phone: encrypt(phone),
      new_pwd: encrypt(pwd),
      action: MobileActions.ForgetPwd,
    };
    const [err, result] = await to(apiLogin.forgetPwd(data));
    if (err) return Promise.reject(err);
    return result;
  };

  // 获取登录状态
  const getLoginStatus = async () => {
    const [err, result] = await to(apiUser.getUserinfo());
    if (err) {
      updateState({ hadLogin: false });
      return false;
    }
    updateState({ userInfo: result, hadLogin: true });
    return true;
  };

  //
  const checkProduct = async (phone: string) => {
    const data = { phone: encrypt(phone), source };
    const [err, result] = await to(apiLogin.checkProduct(data));
    if (err) return Promise.reject(err);
    return result;
  };

  return {
    logout,
    loginAccount,
    loginMobile,
    loginWeChat,
    loginPlugin,
    loginQRCode,
    verifyMobile,
    bindMobile,
    updateMobile,
    sendMobileCode,
    forgetPassword,
    getLoginStatus,
    checkProduct,
  };
}

export default useLogin;
