import {
  MutableRefObject,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Watermark } from '@pansy/watermark';
import { throttle } from '../../utils/data/throttle';
import { Conversation, ConversationItem } from '../../types/chat';
import { Session } from '../../types/chat';

import HomeContext from '../../pages/home/home.context';

import toast from 'react-hot-toast';
import { saveConversation } from '../../utils/app/conversation';
import { ChatInput } from './ChatInput';
import { ChatLoader } from './ChatLoader';
import { MemoizedChatMessage } from './MemoizedChatMessage';

import { reqChat, reqDeleteChat, reqNewSession, reqDeleteSession, chatQuestion } from '../../api';
import { getConversation } from '../../utils/app/conversation';
import { saveSession } from '../../utils/app/session';
import BeianLogo from '../../../assets/images/beian.png';
import './chatwxb.less';
interface Props {
  stopConversationRef: MutableRefObject<boolean>;
}

export const ChatWXB = memo(({ stopConversationRef }: Props) => {
  const watermark = useRef<Watermark>();
  const {
    state: {
      selectedConversation,
      conversations,
      messageIsStreaming,
      modelError,
      loading,
      prompts,
      userId,
      sessions,
      selectedSession,
      sensitive,
    },
    handleUpdateSession,
    dispatch: homeDispatch,
  } = useContext(HomeContext);

  const username = localStorage.getItem('username') || userId || '';

  const [currentMessage, setCurrentMessage] = useState<ConversationItem>(); // 当天聊天信息
  const [autoScrollEnabled, setAutoScrollEnabled] = useState<boolean>(true); // 是否允许自动滚动
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showScrollDownButton, setShowScrollDownButton] = useState<boolean>(false);
  const [isRegenerate, setIsRegenerate] = useState<boolean>(false);
  // const [isSensitive, setIsSensitive] = useState(sensitive); // 是否敏感词
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [queryLoading, setQueryLoading] = useState<boolean>(false);

  useEffect(() => {
    // const lightMode = localStorage.getItem('theme');
    // console.log(lightMode);
    const uid = localStorage.getItem('u-id') || '默认用户';
    watermark.current = new Watermark({
      text: [uid, 'AI 生成内容仅供参考'],
      blindText: username,
      container: 'watermark-1',
      // fontColor: lightMode && lightMode === 'light' ? '#000' : '#fff',
      width: 300,
      height: 200,
      opacity: 0.15,
    });

    // return () => {
    //   if(watermark.current) {
    //     watermark.current.destroy();
    //   }
    // };
  }, []);

  // 删除当前会话
  const handleDeleteSession = async () => {
    await reqDeleteSession(selectedSession?.sessionId);
  };

  // 创建一个新的对话/聊天
  const handleNewSession = async () => {
    const result: any = await reqNewSession(userId);
    if (result.status === 0) {
      toast.success('新建会话成功！');
      const newSession: Session = {
        sessionId: result.sessionId,
        sessionTitle: '未命名',
      };

      // 插入到对话列表中
      const updatedSessions = [...sessions, newSession];

      homeDispatch({ field: 'selectedSession', value: newSession }); // 被选中的对话
      homeDispatch({ field: 'sessions', value: updatedSessions }); // 全部的对话列表

      // 存储被选中的对话和对话列表
      // saveConversation(newConversation);
      // saveConversations(updatedConversations);
      saveSession(newSession);

      homeDispatch({ field: 'loading', value: false });
    } else {
      toast.error('新建会话失败！');
    }
  };

  const handleSecurityP0 = () => {
    toast.error('换个问题试试吧！');
    handleDeleteSession();
    setTimeout(() => {
      handleNewSession();
      homeDispatch({ field: 'loading', value: false });
      homeDispatch({ field: 'messageIsStreaming', value: false });
    }, 1000);
  };

  const handleSecurityP1 = () => {
    // setIsSensitive(true);
    // let updatedConversation: any;
    // updatedConversation = { ...selectedConversation };
    // const updatedMessages: ConversationItem[] = [
    //   ...updatedConversation.chatList,
    //   {
    //     chatRole: 'assistant', chatContent: '您的问题可能违反了我们的内容安全策略，我们可能无法为您提供回答服务。您也可以点击右上方的反馈按钮，您的反馈将帮助我们进行服务的持续优化。'
    //   },
    // ];
    // updatedConversation.chatList = updatedMessages;
    // homeDispatch({
    //   field: 'selectedConversation',
    //   value: { ...updatedConversation },
    // });
    // homeDispatch({ field: 'loading', value: false });
    // homeDispatch({ field: 'messageIsStreaming', value: false });
  };

  const handleSensitive = () => {
    // setIsSensitive(false)
    // handleDeleteSession();
    handleNewSession();
  }

  // 发送聊天信息?
  const handleSend = useCallback(
    async (message: ConversationItem) => {
      if (selectedConversation) {
        handleScrollDown();
        // 如果上一次对话还未完成...
        if (loading) {
          alert('请等待本次对话结束！');
          return;
        }
        let updatedConversation: Conversation;
        // 存储到当前会话的聊天信息列表中
        selectedConversation.chatList.push(message);
        // 更新聊天信息
        updatedConversation = { ...selectedConversation };
        // 存储聊天信息
        homeDispatch({
          field: 'selectedConversation',
          value: updatedConversation,
        });
        saveConversation(updatedConversation);

        // 加载聊天信息中
        homeDispatch({ field: 'loading', value: true });
        homeDispatch({ field: 'messageIsStreaming', value: true });

        const conversationList: any = selectedConversation.chatList.map((item) => {
          return {
            role: item.chatRole,
            content: item.chatContent,
          };
        });

        const chatParams = {
          userId,
          sessionId: selectedSession?.sessionId,
          modelName: 'dlm-1',
          question: message.chatContent,
          conversation: conversationList,
        };

        const { headers, data: response }: any = await reqChat(chatParams);

        // P0: 10001 (给出 toast, 创建新会话，删除当前会话)
        if (response?.status == '10001') {
          handleSecurityP0();
          return;
        }

        // P1: 10002 (给出 toast, 创建新会话，隐藏对话框)
        // if (headers['sensitive']) {
        // handleSecurityP1();
        // return;
        // }

        // error
        if (response?.status == 'error') {
          toast.error('响应失败，请重新尝试！');
        }
        // 如果请求超时
        if (response?.data?.status && response.data.status == '200001') {
          // 停止响应
          toast.error('响应失败，请重新尝试！');
        }

        // 如果是第一次对话，修改会话名
        if (selectedConversation.chatList.length === 1) {
          const { chatContent } = message;
          const customName =
            chatContent.length > 30 ? chatContent.substring(0, 30) + '...' : chatContent;
          handleUpdateSession(customName, selectedSession);
          homeDispatch({
            field: 'selectedSession',
            value: { ...selectedSession, sessionName: customName },
          });
          saveSession(selectedSession);
        }

        homeDispatch({ field: 'loading', value: false });
        homeDispatch({ field: 'sensitive', value: headers.sensitive });

        const updatedMessages: ConversationItem[] = [
          ...updatedConversation.chatList,
          { chatRole: 'assistant', chatContent: response },
        ];
        updatedConversation.chatList = updatedMessages;
        homeDispatch({
          field: 'selectedConversation',
          value: { ...updatedConversation },
        });
        handleScrollDown();
        const { sensitive, result } = await getConversation(selectedSession ? selectedSession?.sessionId : 0);
        if (sensitive) {
          homeDispatch({
            field: 'sensitive',
            value: sensitive,
          })
        }
        updatedConversation.chatList = result;
        homeDispatch({
          field: 'selectedConversation',
          value: { ...updatedConversation },
        });
        saveConversation({ chatList: result });
        homeDispatch({ field: 'messageIsStreaming', value: false });
      }
    },
    [conversations, selectedConversation, stopConversationRef],
  );

  // 滚动到底部
  const scrollToBottom = useCallback(() => {
    if (autoScrollEnabled) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      textareaRef.current?.focus();
    }
  }, [autoScrollEnabled]);

  // 处理滚动事件
  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const bottomTolerance = 30;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        setAutoScrollEnabled(false);
        setShowScrollDownButton(true);
      } else {
        setAutoScrollEnabled(true);
        setShowScrollDownButton(false);
      }
    }
  };

  // 处理滚动到底部的事件
  const handleScrollDown = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleSettings = () => {
    setShowSettings(!showSettings);
  };

  // 清除所有聊天记录
  // const onClearAll = () => {
  //   if (
  //     confirm('你确定要清空所有聊天记录吗？') &&
  //     selectedConversation
  //   ) {
  //     // 将聊天记录置空
  //     // handleUpdateConversation(selectedConversation, {
  //     //   key: 'messages',
  //     //   value: [],
  //     // });
  //   }
  // };

  // 滚动到底部
  const scrollDown = () => {
    if (autoScrollEnabled) {
      messagesEndRef.current?.scrollIntoView(true);
    }
  };
  const throttledScrollDown = throttle(scrollDown, 250);

  // 重新生成对话响应
  const onRegenerate = async (currentMessage: any) => {
    const chatMessage = selectedConversation?.chatList[selectedConversation?.chatList.length - 2];
    const assistantMessage =
      selectedConversation?.chatList[selectedConversation?.chatList.length - 1];
    // const chatResult: any = await reqDeleteChat(chatMessage?.chatId);
    // const assistantResult: any = await reqDeleteChat(assistantMessage?.chatId);
    // 如果最后一条对话是用户发的
    let flag = false;
    if (assistantMessage?.chatRole === 'user') {
      const assistantResult: any = await reqDeleteChat(assistantMessage?.chatId);
      flag = assistantResult.status === 0 ? true : false;
    } else {
      const chatResult: any = await reqDeleteChat(chatMessage?.chatId);
      const assistantResult: any = await reqDeleteChat(assistantMessage?.chatId);
      flag = chatResult.status === 0 && assistantResult.status === 0 ? true : false;
    }
    // const chatResult: any = await reqDeleteChat(chatMessage?.chatId);
    // const assistantResult: any = await reqDeleteChat(assistantMessage?.chatId);
    if (flag) {
      let updatedConversation = { ...selectedConversation };
      const { sensitive, result } = await getConversation(selectedSession ? selectedSession.sessionId : 0);
      if (sensitive) {
        homeDispatch({
          field: 'sensitive',
          value: sensitive,
        })
        // setIsSensitive(true);
      }
      updatedConversation.chatList = result;
      homeDispatch({
        field: 'selectedConversation',
        value: { ...updatedConversation },
      });
      saveConversation({ chatList: result });
      // setCurrentMessage(currentMessage);
      setIsRegenerate(!isRegenerate);
    } else {
      toast.error('响应失败，请重试...');
    }
  };

  // useEffect(() => {
  //   console.log('currentMessage', currentMessage);
  //   if (currentMessage) {
  //     handleSend(currentMessage);
  //     homeDispatch({ field: 'currentMessage', value: undefined });
  //   }
  // }, [currentMessage]);

  // useEffect(() => {
  //   throttledScrollDown();
  //   selectedConversation &&
  //     setCurrentMessage(
  //       selectedConversation?.meta?.content?.parts[0] || {}
  //     );
  // }, [selectedConversation, throttledScrollDown]);

  useEffect(() => {
    throttledScrollDown();

    if (selectedConversation) {
      const n = selectedConversation.chatList.length;
      for (let i = n - 1; i >= n - 2; i--) {
        if (selectedConversation?.chatList[i]?.chatRole === 'user') {
          setCurrentMessage(selectedConversation.chatList[i]);
        }
      }
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (currentMessage) {
      console.log('重新发送请求', currentMessage);
      handleSend(currentMessage);
    }
  }, [isRegenerate]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAutoScrollEnabled(entry.isIntersecting);
        if (entry.isIntersecting) {
          textareaRef.current?.focus();
        }
      },
      {
        root: null,
        threshold: 0.5,
      },
    );
    const messagesEndElement = messagesEndRef.current;
    if (messagesEndElement) {
      observer.observe(messagesEndElement);
    }
    return () => {
      if (messagesEndElement) {
        observer.unobserve(messagesEndElement);
      }
    };
  }, [messagesEndRef]);

  const hintSensitive = async (question: string) => {
    setQueryLoading(true);
    const result: any = await chatQuestion({
      question,
    });
    setQueryLoading(false);
    const isSensitive = result?.status == 10001;
    return !!isSensitive;
  }

  const handleRespChat = async (message: ConversationItem) => {
    const { chatContent } = message;
    const isSensitive = await hintSensitive(chatContent);
    if (isSensitive) {
      // 当前 conversion 是否有历史记录
      if (selectedConversation?.chatList?.length === 0) {
        handleDeleteSession();
      }
      handleNewSession();
      return;
    }
    setCurrentMessage(message);
    handleSend(message);
  }

  return (
    <div id="watermark-1" className="relative flex-1 overflow-hidden bg-white dark:bg-[#343541]">
      {/* If no login */}
      <>
        <div
          className="max-h-full overflow-x-hidden"
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          {/* If chat list is null */}
          {!selectedConversation ? (
            <>
              <div className="mx-auto flex h-full w-[400px] flex-col justify-center space-y-6 sm:w-[700px] mt-[20%]">
                <div className="text-center text-4xl font-bold text-black dark:text-white">
                  欢迎来到语鲸大模型 - ({username})
                </div>
                <div className="text-center text-lg text-black dark:text-white">
                  <div className="mb-2 font-bold">由深言科技研发运营</div>
                </div>
                <div className="text-center text-gray-500 dark:text-gray-400">
                  <div className="mb-2">请先点击左上角创建一个新的聊天进行体验。</div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* 聊天信息列表 */}
              {selectedConversation?.chatList &&
                selectedConversation.chatList.map((message: any, index: number) => (
                  <MemoizedChatMessage
                    key={index}
                    message={message}
                    messageIndex={index}
                    onEdit={handleRespChat}

                  />
                ))}
              {
                queryLoading && <div
                  className="group md:px-4 border-b border-black/10 bg-gray-50 text-gray-800 dark:border-gray-900/50 dark:bg-[#444654] dark:text-gray-100"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  <div className="relative m-auto flex p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-2xl lg:px-0 xl:max-w-3xl">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                </div>
              }


              {/* Show loading page */}
              {loading && <ChatLoader />}

              <div className="h-[162px] bg-white dark:bg-[#343541]" ref={messagesEndRef} />
              {/* Chat input */}

              {
                sensitive
                  ? <div className="absolute bottom-12 left-0 w-full border-transparent bg-gradient-to-b from-transparent via-white to-white pt-6 dark:border-white/20 dark:via-[#343541] dark:to-[#343541] md:pt-2">
                    <div className="stretch mx-2 mt-4 flex flex-row last:mb-2 md:mx-4 md:mt-[52px] md:last:mb-6 items-center justify-center">
                      换个话题重新开始吧，<button className="cursor-pointer select-none text-blue-600/100" onClick={handleSensitive}>新建会话</button>
                    </div>
                  </div>
                  : <ChatInput
                    stopConversationRef={stopConversationRef}
                    textareaRef={textareaRef}
                    onSend={handleRespChat}
                    onScrollDownClick={handleScrollDown}
                    onRegenerate={async () => {
                      if (currentMessage) {
                        await onRegenerate(currentMessage);
                        // handleSend(currentMessage);
                      }
                    }}
                    showScrollDownButton={showScrollDownButton}
                  />
              }
            </>
          )}

        </div>
      </>
      <div
        className='absolute bottom-0 left-0 w-full border-transparent bg-gradient-to-b from-transparent via-white to-white pt-6 dark:border-white/20 dark:via-[#343541] dark:to-[#343541] md:pt-2'>
        <p className="text-center text-[12px] text-black/50 dark:text-white/50">
          请遵守
          <a href="/agreement" className="text-blue-600/100" target="_blank">
            《服务协议》
          </a>、
          <a href="/privacy" className="text-blue-600/100" target="_blank">
            《隐私协议》
          </a>
          ，
          基于语鲸大模型
        </p>
        <div className="flex justify-center items-center mb-4">
          <img src={BeianLogo} alt="备案" className="w-4 h-4 mr-2" />
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802043273" rel="noreferrer" target="_blank">京公网安备11010802043273</a>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank" className="ml-4">京ICP备2022009790号-5</a>
        </div >
      </div>
      {/* <Toaster /> */}
    </div>
  );
});
ChatWXB.displayName = 'ChatWXB';
