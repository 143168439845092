/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Lemon.Feng
 * @LastEditTime: 2023-06-30 01:15:58
 * @FilePath: /deep-chat/utils/app/clean.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Session } from '../../types/chat';

export const cleanSelectedConversation = (session: Session) => {
  let updatedSession = session;
  return updatedSession;
};

export const cleanConversationHistory = (history: any[]): Session[] => {
  // added model for each session (3/20/23)
  // added system prompt for each session (3/21/23)
  // added folders (3/23/23)
  // added prompts (3/26/23)
  // added messages (4/16/23)

  if (!Array.isArray(history)) {
    console.warn('history is not an array. Returning an empty array.');
    return [];
  }

  return history.reduce((acc: any[], session) => {
    try {
      acc.push(session);
      return acc;
    } catch (error) {
      console.warn(`error while cleaning conversations' history. Removing culprit`, error);
    }
    return acc;
  }, []);
};
