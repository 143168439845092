import { HTMLAttributes } from 'react';
import { useDeepGuard } from '../../index';
import { AuthenticatedType, LoginType, ModalShowType } from '../../utils/constants';
import LoginForm from '../../components/login-form';
import PasswordUpdate from '../../components/password-update';
import styles from './index.module.css';
import Authenticated from '../../components/authenticated';
import PasswordSetting from '../../components/password-setting';
import PasswordForget from '../../components/password-forget';
import MobileBind from '../../components/mobile-bind';

const LoginPage = (props: HTMLAttributes<HTMLDivElement>) => {
  const { state } = useDeepGuard();

  return (
    <div {...props} className={styles.page}>
      {state.showType === ModalShowType.Login && <LoginForm />}
      {state.showType === ModalShowType.LoginPwd && <LoginForm type={LoginType.PASSWORD_LOGIN} />}
      {state.showType === ModalShowType.PwdUpdate && <PasswordUpdate />}
      {state.showType === ModalShowType.AuthenticatedPwdForget && (
        <Authenticated type={AuthenticatedType.PwdForget} />
      )}
      {state.showType === ModalShowType.SettingPwd && <PasswordSetting />}
      {state.showType === ModalShowType.PwdForget && <PasswordForget />}
      {state.showType === ModalShowType.PwdUpdate && <PasswordUpdate />}
      {state.showType === ModalShowType.MobileBind && <MobileBind />}
    </div>
  );
};

export default LoginPage;
