/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-06-29 11:27:07
 * @FilePath: /deep-chat/components/Chatbar/components/Conversations.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Session } from '../../../types/chat';

import { ConversationComponent } from './Conversation';

interface Props {
  sessions: Session[];
}

export const Conversations = ({ sessions }: Props) => {
  return (
    <div className="flex w-full flex-col gap-1">
      {sessions
        // .filter((conversation) => !conversation.conversation_name)
        // .slice()
        .reverse()
        .map((session, index) => (
          <ConversationComponent key={index} session={session} />
        ))}
    </div>
  );
};
