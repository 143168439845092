import { useContext, useEffect, useState } from 'react';

import { useCreateReducer } from '../..//hooks/useCreateReducer';

import { saveFolders } from '../../utils/app/folders';
import { exportData, importData } from '../../utils/app/importExport';

import { Session } from '../../types/chat';
import { LatestExportFormat, SupportedExportFormats } from '../../types/export';

import HomeContext from '../../pages/home/home.context';

import { ChatFolders } from './components/ChatFolders';
import { ChatbarSettings } from './components/ChatbarSettings';
import { Conversations } from './components/Conversations';

import toast, { Toaster } from 'react-hot-toast';
import { reqDeleteSession } from '../../api';
import { saveConversation } from '../../utils/app/conversation';
import { getSessions, saveSession, saveSessions } from '../../utils/app/session';
import Sidebar from '../Sidebar';
import ChatbarContext from './Chatbar.context';
import { ChatbarInitialState, initialState } from './Chatbar.state';

export const Chatbar = () => {
  const chatBarContextValue = useCreateReducer<ChatbarInitialState>({
    initialState,
  });

  const {
    state: { conversations, selectedSession, showChatbar, folders, userId },
    dispatch: homeDispatch,
    handleCreateFolder,
    handleNewSession,
    handleUpdateSession,
  } = useContext(HomeContext);

  const {
    state: { searchTerm, filteredSessions },
    dispatch: chatDispatch,
  } = chatBarContextValue;

  const [isFolderUpdate, setIsFolderUpdate] = useState<boolean>(false);

  // 导出会话
  const handleExportData = () => {
    exportData();
  };

  // 导入会话
  const handleImportSessions = (data: SupportedExportFormats) => {
    const { history, folders, prompts }: LatestExportFormat = importData(data);
    homeDispatch({ field: 'sessions', value: history });
    homeDispatch({
      field: 'selectedSession',
      value: history[history.length - 1],
    });
    homeDispatch({ field: 'folders', value: folders });
    homeDispatch({ field: 'prompts', value: prompts });

    window.location.reload();
  };

  // 清除会话列表
  const handleClearSessions = () => {
    homeDispatch({
      field: 'selectedSession',
      value: undefined,
    });

    homeDispatch({ field: 'sessions', value: [] });

    localStorage.removeItem('sessionHistory');
    localStorage.removeItem('selectedSession');

    const updatedFolders = folders.filter((f) => f.type !== 'chat');

    homeDispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);
  };

  // 处理删除会话
  const handleDeleteSession = async (session: Session) => {
    const result: any = await reqDeleteSession(session?.sessionId);
    if (result.status === 0) {
      // const sessionList = await getSessions(userId);
      toast.success('删除会话成功！');
      // homeDispatch({
      //   field: 'sessions',
      //   value: sessionList
      // });
      // saveSessions(sessionList);
      /*
      homeDispatch({
        field: 'selectedSession',
        value: sessionList[0].sessions[0]
      });
      saveSession(sessionList[0].sessions[0]);
      */
      homeDispatch({
        field: 'selectedSession',
        value: undefined,
      });
      homeDispatch({
        field: 'selectedConversation',
        value: undefined,
      });
      saveSession(undefined);
      saveConversation(undefined);
      setIsFolderUpdate(!isFolderUpdate);
    } else {
      toast.error('删除会话失败！');
    }
  };

  // 处理修改会话名称
  const handleRenameSession = async (renameValue: string, session: Session | undefined) => {
    handleUpdateSession(renameValue, session);
    setIsFolderUpdate(!isFolderUpdate);
  };

  const handleToggleChatbar = () => {
    homeDispatch({ field: 'showChatbar', value: !showChatbar });
    localStorage.setItem('showChatbar', JSON.stringify(!showChatbar));
  };

  const handleDrop = (e: any) => {
    if (e.dataTransfer) {
      const session = JSON.parse(e.dataTransfer.getData('session'));
      // handleUpdateSession(session, { key: 'folderId', value: 0 });
      chatDispatch({ field: 'searchTerm', value: '' });
      e.target.style.background = 'none';
    }
  };

  // 新建会话
  const handleCreateItem = () => {
    handleNewSession();
    setIsFolderUpdate(!isFolderUpdate);
  };

  const handleUpdateSessions = async () => {
    const sessionList = await getSessions(userId);
    homeDispatch({
      field: 'sessions',
      value: sessionList,
    });
    saveSessions(sessionList);
    setIsFolderUpdate(!isFolderUpdate);
  };

  useEffect(() => {
    if (searchTerm) {
      // chatDispatch({
      //   field: 'filteredConversations',
      //   value: conversations.filter((conversation) => {
      //     const searchable =
      //       conversation.conversation_name.toLocaleLowerCase() +
      //       ' ' +
      //       conversation?.meta.content.conversation.map((message) => message.content).join(' ');
      //     return searchable.toLowerCase().includes(searchTerm.toLowerCase());
      //   }),
      // });
    } else {
      // chatDispatch({
      //   field: 'filteredConversations',
      //   value: conversations,
      // });
    }
  }, [searchTerm, conversations]);

  useEffect(() => {
    // const sessionList = await getSessions(userId);
    // homeDispatch({
    //   field: 'sessions',
    //   value: sessionList
    // });
    // saveSessions(sessionList);
    // setIsFolderUpdate(!isFolderUpdate);
    handleUpdateSessions();
  }, [selectedSession]);

  return (
    <ChatbarContext.Provider
      value={{
        ...chatBarContextValue,
        handleDeleteSession,
        handleRenameSession,
        handleClearSessions,
        handleImportSessions,
        handleExportData,
      }}
    >
      <Sidebar
        side={'left'}
        isOpen={showChatbar}
        addItemButtonTitle={'新建会话'}
        itemComponent={<Conversations sessions={filteredSessions} />}
        folderComponent={<ChatFolders isFolderUpdate={isFolderUpdate} searchTerm={searchTerm} />}
        items={filteredSessions}
        searchTerm={searchTerm}
        handleSearchTerm={(searchTerm: string) =>
          chatDispatch({ field: 'searchTerm', value: searchTerm })
        }
        toggleOpen={handleToggleChatbar}
        handleCreateItem={handleCreateItem}
        handleCreateFolder={() => handleCreateFolder('新建文件夹', 'chat')}
        handleDrop={handleDrop}
        footerComponent={<ChatbarSettings />}
      />
      {/* <Toaster /> */}
    </ChatbarContext.Provider>
  );
};
