import { Button, Form, Input, Space, message } from 'antd';
import cx from 'classnames';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import styles from './index.module.css';
import styleCommon from '../style/index.module.css';
import { MobileActions, ModalShowType, ProductSource, errorText, useDeepGuard } from '../../index';
import Prompt from '../../assets/prompt.url.svg';
import checkcircle from '../../assets/checkcircle.url.svg';
import to from '../../utils/await-to';
import useCountdown from '../../hooks/useCountDown';

type FieldType = {
  phone?: string;
  phonecode?: string;
};

const MobileBind = () => {
  const deepGuard = useDeepGuard();
  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [codeRequired, setCodeRequired] = useState(false);
  const phoneData = useRef({ phone: '', code: '' });
  const [isSignUpProduct, setIsSignUpProduct] = useState(false);

  const { second, start, reset, isCounting } = useCountdown(60, () => {
    reset();
  });

  const verificationCodeBtnText = useMemo(() => {
    if (isCounting) {
      return `${second}秒`;
    }
    return '获取验证码';
  }, [isCounting, second]);

  const confirmLogin = async (type = 'codeLogin') => {
    type === 'authorizedLogin' && setButtonLoading(true);
    const [err, result] = await to(deepGuard.bindMobile(phoneData.current));

    if (err) {
      setButtonLoading(false);
      if (type === 'codeLogin') {
        if (err.code === 204) {
          form.setFields([{ name: 'phoneCode', errors: [errorText(err.code)] }]);
        } else {
          message.error(err?.msg);
        }
      } else {
        message.error(err?.msg);
      }
      return;
    }
    if (result) {
      message.success('绑定成功');
      if (!result.set_pwd) {
        deepGuard.show(ModalShowType.SettingPwd);
        return;
      }
      deepGuard.hide();
    }
    setButtonLoading(false);
  };

  const onFinish = async (values: any) => {
    const { phone, phoneCode } = values;
    if (phoneCode === '' || phoneCode === undefined) {
      form.setFields([{ name: 'phoneCode', errors: ['请输入验证码'] }]);
      setCodeRequired(true);
      return;
    }
    if (phoneCode) {
      setCodeRequired(false);
    }
    setButtonLoading(true);
    phoneData.current = {
      phone,
      code: phoneCode,
    };
    const [checkErr, checkResult] = await to(deepGuard.checkProduct(phone));
    if (checkErr) {
      setButtonLoading(false);
      if (checkErr.code === 204) {
        form.setFields([{ name: 'phoneCode', errors: [checkErr.msg] }]);
      } else {
        message.error(checkErr.msg);
      }
      return;
    }
    const checkResultArr = checkResult ? checkResult : [];

    const positionArr = checkResultArr.filter((item) => item?.source === ProductSource.Extension);
    if (checkResultArr.length > 0 && positionArr.length === 0) {
      setIsSignUpProduct(true);
      setButtonLoading(false);
      return;
    }
    confirmLogin('codeLogin');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleGetCode = useCallback(
    debounce(async () => {
      getCode();
    }, 200),
    [],
  );

  const getCode = async () => {
    const phoneNumber = await form.validateFields(['phone']);
    if (phoneNumber.phone === '') {
      form.setFields([{ name: 'phone', errors: ['请输入手机号'] }]);
      return;
    }
    const data = {
      phone: phoneNumber.phone,
      action: MobileActions.BindMobile,
    };
    const [err] = await to(deepGuard.sendMobileCode(data));

    if (err) {
      message.error(err.msg);
      return;
    }
    start();
  };

  const phoneNumberFormat = (str: string) => {
    const pho = /(\d{3})\d*(\d{4})/;
    return str.replace(pho, '$1****$2');
  };

  const tipContent = (): JSX.Element => {
    return (
      <div className={styleCommon.cancellateConfirm}>
        <h3 className={cx(styleCommon.mb24, styles.confirmTitle)}>
          <div>该微信已注册深言账号</div>
          <div className={cx(styleCommon.fs14)}>{phoneNumberFormat(phoneData.current.phone)}</div>
        </h3>
        <Button type="primary" block onClick={() => confirmLogin('authorizedLogin')}>
          授权注册并登录本产品
        </Button>
        <Button
          block
          className={cx(styleCommon.fullWidth, styleCommon.mt8)}
          onClick={() => deepGuard.show(ModalShowType.Login)}
        >
          返回登录注册
        </Button>
      </div>
    );
  };
  const MobileForm = (): JSX.Element => {
    return (
      <div className={cx(styleCommon.pr16, styleCommon.pl16)} style={{ width: '427px' }}>
        <h3 className={cx(styleCommon.title, styleCommon.fs16, styleCommon.bold)}>绑定手机号</h3>
        <Form
          name="newPhoneForm"
          form={form}
          validateTrigger={['onBlur']}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            className={styleCommon.formItemContent}
            name="phone"
            rules={[
              { required: true, message: '请输入手机号' },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号',
              },
            ]}
            normalize={(value) => {
              value = value && value.replace(/[^\d]+/g, '');
              return value;
            }}
          >
            <Input placeholder="请输入手机号" allowClear />
          </Form.Item>
          <Form.Item<FieldType>
            wrapperCol={{ span: 24 }}
            className={cx(styleCommon.mb0, styleCommon.formItemContent)}
          >
            <Space align="start">
              <Form.Item
                name="phoneCode"
                rules={[{ required: codeRequired, message: '请输入验证码' }]}
              >
                <Input name="phoneCode" placeholder="请输入验证码" allowClear />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={isCounting}
                  className={cx({
                    [styles.btnDisabled]: isCounting,
                  })}
                  onClick={() => handleGetCode()}
                >
                  {verificationCodeBtnText}
                </Button>
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }} className={styleCommon.formItemContent}>
            <Button
              type="primary"
              className={cx(styleCommon.fullWidth)}
              htmlType="submit"
              loading={buttonLoading}
            >
              绑定
            </Button>
          </Form.Item>
        </Form>
        <div>
          <div className={cx(styleCommon.fs16, styleCommon.bold, styleCommon.alignItemsStart)}>
            为什么要绑定手机号？
          </div>
          <div className={cx(styleCommon.flex, styleCommon.mt8)}>
            <div className={styles.promptImg}>
              <img src={Prompt} alt="" />
            </div>
            <div className={cx(styleCommon.fs12, styleCommon.ml8)}>
              依据《网络安全法》，互联网注册用户要提供基于移动电话号码等方式的验证；
            </div>
          </div>
          <div className={cx(styleCommon.flex, styleCommon.mt8, styleCommon.alignItemsCenter)}>
            <div className={styles.promptImg}>
              <img src={checkcircle} className={styles.promptImgContent} alt="" />
            </div>
            <div className={cx(styleCommon.fs12, styleCommon.ml8)}>
              多种登录方式对应同一账号，数据共享，登录方便；
            </div>
          </div>
          <div className={cx(styleCommon.flex, styleCommon.mt8, styleCommon.alignItemsCenter)}>
            <div className={styles.promptImg}>
              <img src={checkcircle} alt="" />
            </div>
            <div className={cx(styleCommon.fs12, styleCommon.ml8)}>一种失效时仍能顺利登录</div>
          </div>
          <div className={cx(styleCommon.fs12, styleCommon.mt20, styleCommon.textGrey)}>
            注册登录后，可以在【头像】-【个人中心】中，设置更多个人信息。
          </div>
        </div>
      </div>
    );
  };

  return <>{isSignUpProduct ? tipContent() : MobileForm()}</>;
};

export default MobileBind;
