/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-03 16:15:27
 * @FilePath: /deep-chat/pages/api/home/home.state.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Conversation, Message, Session } from '../../types/chat';
import { ErrorMessage } from '../../types/error';
import { FolderInterface } from '../../types/folder';
import { Prompt } from '../../types/prompt';

export interface HomeInitialState {
  apiKey: string;
  loading: boolean;
  lightMode: 'light' | 'dark';
  messageIsStreaming: boolean;
  modelError: ErrorMessage | null;
  folders: FolderInterface[];
  sessions: Session[];
  selectedSession: Session | undefined;
  conversations: Conversation[];
  selectedConversation: Conversation | undefined;
  currentMessage: Message | undefined;
  prompts: Prompt[];
  temperature: number;
  showChatbar: boolean;
  showPromptbar: boolean;
  currentFolder: FolderInterface | undefined;
  messageError: boolean;
  searchTerm: string;
  userId: string | undefined;
  sensitive: boolean;
}

export const initialState: HomeInitialState = {
  apiKey: '',
  loading: false,
  lightMode: 'dark',
  messageIsStreaming: false,
  modelError: null,
  folders: [],
  sessions: [],
  selectedSession: undefined,
  conversations: [],
  selectedConversation: undefined,
  currentMessage: undefined,
  prompts: [],
  temperature: 1,
  // showPromptbar: true, // 是否展示右边关键字设置栏
  showPromptbar: false,
  showChatbar: true,
  currentFolder: undefined,
  messageError: false,
  searchTerm: '',
  userId: undefined,
  sensitive: false,
};
