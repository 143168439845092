import { Button, Form, Input, message } from 'antd';
import cx from 'classnames';
import styles from '../style/index.module.css';
import { ModalShowType, useDeepGuard, AuthenticatedType } from '../../index';
import to from '../../utils/await-to';
import inputSuffix from '../../assets/inputSuffix.url.svg';
import inputSuffixSee from '../../assets/inputSuffixSee.url.svg';

type FieldType = {
  password?: string;
  passwordConfirm?: string;
};

const PasswordForget = ({ type }: { type?: AuthenticatedType }) => {
  const deepGuard = useDeepGuard();

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async (values: any) => {
    const data = {
      pwd: values.password,
      phone: deepGuard.state?.phone || '',
    };
    const [err] = await to(deepGuard.forgetPassword(data));

    if (err) {
      message.error(err.msg);
      return;
    }
    message.success('密码重置成功');
    if (type === AuthenticatedType.PwdForgetUpdate) {
      deepGuard.hide();
      setTimeout(() => {
        window.open('/', '_self');
      }, 1000);
      return;
    }
    deepGuard.show(ModalShowType.LoginPwd);
  };

  return (
    <div className={cx(styles.pl16, styles.pr16)} style={{ width: ' 292px' }}>
      <h3 className={styles.title}>重置密码</h3>
      <Form
        form={form}
        validateTrigger={['onBlur']}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          className={styles.formItemContent}
          name="password"
          rules={[
            {
              required: true,
              message: '至少为6个字符，需包含数字和字母，不支持符号',
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
              message: '至少为6个字符，需包含数字和字母，不支持符号',
            },
          ]}
        >
          <Input.Password
            name="password"
            placeholder="请输入新密码"
            maxLength={50}
            iconRender={(visible) =>
              visible ? <img src={inputSuffixSee} alt="" /> : <img src={inputSuffix} alt="" />
            }
          />
        </Form.Item>
        <Form.Item
          className={styles.formItemContent}
          name="passwordConfirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: '请再次输入密码',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次密码输入不一致!'));
              },
            }),
          ]}
        >
          <Input.Password
            name="password"
            placeholder="请再次输入密码"
            maxLength={50}
            iconRender={(visible) =>
              visible ? <img src={inputSuffixSee} alt="" /> : <img src={inputSuffix} alt="" />
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} className={styles.formItemContent}>
          <Button type="primary" className={cx(styles.fullWidth)} htmlType="submit">
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordForget;
