/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Lemon.Feng
 * @LastEditTime: 2023-06-30 03:23:58
 * @FilePath: /deep-chat/pages/api/home/home.context.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Dispatch, createContext } from 'react';

import { ActionType } from '../../hooks/useCreateReducer';

import { Session } from '../../types/chat';
import { FolderType } from '../../types/folder';

import { useCreateReducer } from '../../hooks/useCreateReducer';

import { HomeInitialState, initialState } from './home.state';

export interface HomeContextProps {
  state: HomeInitialState;
  dispatch: Dispatch<ActionType<HomeInitialState>>;
  handleNewSession: () => void;
  // handleNewConversation: () => void;
  handleCreateFolder: (name: string, type: FolderType) => void;
  handleDeleteFolder: (folderId: string) => void;
  handleUpdateFolder: (folderId: string, name: string) => void;
  handleSelectSession: (conversation: Session) => void;
  handleUpdateSession: (renameValue: string, conversation: Session | undefined) => void;
  // handleSelectConversation: (conversation: Conversation) => void;
  // handleUpdateConversation: (
  //   conversation: Conversation,
  //   data: KeyValuePair,
  // ) => void;
}

const HomeContext = createContext<HomeContextProps>(undefined!);

export default HomeContext;
