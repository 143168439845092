import { SourcePrefix } from './utils/constants';

interface IData {
  source: number;
  prefix?: string;
  appId?: string;
  config: {
    privacyPolicyUrl: string;
    userProtocolUrl: string;
    copyrightUrl: string;
  };
}

class DeepGuardConfig {
  private _data: IData = {
    source: 0,
    prefix: SourcePrefix[0],
    appId: '',
    config: {
      privacyPolicyUrl: '',
      userProtocolUrl: '',
      copyrightUrl: '',
    },
  };
  init(config: IData) {
    this._data = config;
  }
  getConfig() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { source, prefix } = this?._data;
    if (!prefix) {
      const newPrefix = SourcePrefix[source];
      this._data.prefix = newPrefix;
    }
    return this._data;
  }
}

const deepGuardConfig = new DeepGuardConfig();

export default deepGuardConfig;
