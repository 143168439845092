import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { cleanSelectedConversation } from '../../utils/app/clean';
import { getSettings } from '../../utils/app/settings';

import { Session } from '../../types/chat';

import { Chatbar } from '../../components/Chatbar/Chatbar';
import { Navbar } from '../../components/Mobile/Navbar';

import HomeContext from './home.context';

import { reqConversationDetail } from '../../api';
import { ChatWXB } from '../../components/Chat/ChatWXB';
import { saveConversation } from '../../utils/app/conversation';
// import useLogin from '../../hooks/useLogin';
import { useDeepGuard } from '../../deep-auth';
import to from '../../deep-auth/utils/await-to';
const Home = () => {
  const deepGuard = useDeepGuard();
  const navigate = useNavigate();
  const {
    state: { lightMode, sessions, selectedSession, userId = localStorage.getItem('u-id') || '' },
    dispatch,
    handleNewSession,
  } = useContext(HomeContext);

  const stopConversationRef = useRef<boolean>(false);

  useEffect(() => {
    if (!deepGuard.state.hadLogin && !deepGuard.state.loading) {
      navigate('/login');
    }
    // deepGuard.getUserinfo();
  }, [deepGuard.state.hadLogin]);

  const updateLoginStatus = async () => {
    const [err] = await to(deepGuard.getUserinfo());
    if (err) {
      navigate('/login');
      return;
    }
  };

  useEffect(() => {
    updateLoginStatus();
  }, []);

  useEffect(() => {
    getCurrentConversation(selectedSession || {});
  }, [selectedSession]);

  useEffect(() => {
    if (window.innerWidth < 640) {
      dispatch({ field: 'showChatbar', value: false });
    }
  }, [selectedSession]);

  // 获取当前对话的聊天记录
  const getCurrentConversation = async (session: any) => {
    if (session.sessionId) {
      const { headers, data: result }: any = await reqConversationDetail(session.sessionId, userId);
      if (result.status === 0) {
        dispatch({
          field: 'selectedConversation',
          value: {
            chatList: result.chatList,
          },
        });
        dispatch({
          field: 'sensitive',
          value: headers.sensitive,
        });
        saveConversation({ chatList: result.chatList });
      }
    }
  };

  // 渲染主题 & 渲染界面 & 获取key进行登录
  useEffect(() => {
    // 渲染主题 dark | light
    const settings = getSettings();
    if (settings.theme) {
      dispatch({
        field: 'lightMode',
        value: settings.theme,
      });
    }

    // 获取用户id
    // const userId = cookie.load('userId');
    const userId = localStorage.getItem('u-id');
    if (userId) {
      dispatch({
        field: 'userId',
        value: userId,
      });
    }

    // 浏览器窗口判断
    // if (window.innerWidth < 640) {
    //   dispatch({ field: 'showChatbar', value: false });
    //   dispatch({ field: 'showPromptbar', value: false });
    // }

    // 是否展示聊天相关导航栏(左)
    const showChatbar = localStorage.getItem('showChatbar');
    if (showChatbar) {
      dispatch({ field: 'showChatbar', value: showChatbar === 'true' });
    }

    // 是否展示关键词相关导航栏(右)
    // const showPromptbar = localStorage.getItem('showPromptbar');
    // if (showPromptbar) {
    //   dispatch({ field: 'showPromptbar', value: showPromptbar === 'true' });
    // }

    // 文件夹相关操作
    // const folders = localStorage.getItem('folders');
    // if (folders) {
    //   dispatch({ field: 'folders', value: JSON.parse(folders) });
    // }

    // 关键字相关操作
    // const prompts = localStorage.getItem('prompts');
    // if (prompts) {
    //   dispatch({ field: 'prompts', value: JSON.parse(prompts) });
    // }

    // 聊天历史记录
    // const conversationHistory = localStorage.getItem('conversationHistory');
    // if (conversationHistory) {
    //   const parsedConversationHistory: Conversation[] =
    //     JSON.parse(conversationHistory);
    //   const cleanedConversationHistory = cleanConversationHistory(
    //     parsedConversationHistory,
    //   );

    //   dispatch({ field: 'sessions', value: cleanedConversationHistory });
    // }

    // 当前聊天
    const selectedSession = localStorage.getItem('selectedSession');
    if (selectedSession) {
      const parsedSelectedSession: Session = JSON.parse(selectedSession);
      const cleanedSelectedSession = cleanSelectedConversation(parsedSelectedSession);

      dispatch({
        field: 'selectedSession',
        value: cleanedSelectedSession,
      });
    } else {
      if (sessions) {
        const lastSession = sessions[sessions.length - 1];
        dispatch({
          field: 'selectedSession',
          value: lastSession,
        });
      }
    }
  }, [dispatch]);

  return (
    <>
      <main
        className={`flex h-screen w-screen flex-col text-sm text-white dark:text-white ${lightMode}`}
      >
        <div className="fixed top-0 w-full sm:hidden">
          <Navbar selectedSession={selectedSession} onNewSession={handleNewSession} />
        </div>
        <div className="flex h-full w-full pt-[48px] sm:pt-0">
          <Chatbar />

          <div className="flex flex-1">
            {/* <Chat stopConversationRef={stopConversationRef} /> */}
            <ChatWXB stopConversationRef={stopConversationRef} />
          </div>

          {/* <Promptbar /> */}
        </div>
      </main>
      {/* )} */}
    </>
  );
};
export default Home;
