import { useContext, useEffect } from 'react';
import { DeepLoginPage, EventKeys, LoginType, ModalShowType, storage, useDeepGuard } from '../../deep-auth';
import BeianLogo from '../../../assets/images/beian.png';
import { useNavigate } from 'react-router-dom';
import './index.less';
import HomeContext from '../home/home.context';

const Login = () => {
  const deepGuard = useDeepGuard();
  const navigate = useNavigate();
  const isLogged = deepGuard.state.hadLogin;
  const { dispatch: homeDispatch } = useContext(HomeContext);

  useEffect(() => {
    if (isLogged && !deepGuard.state.loading) {
      navigate('/');
    }
  }, [isLogged, deepGuard.state.loading]);

  useEffect(() => {
    deepGuard.on(EventKeys.LOGOUT, () => {
      if (storage.get('loginType') === LoginType.PASSWORD_LOGIN) {
        deepGuard.show(ModalShowType.LoginPwd);
        return;
      }
      deepGuard.show(ModalShowType.Login);
    });
  }, []);

  useEffect(() => {
    if (storage.get('loginType') === LoginType.PASSWORD_LOGIN) {
      deepGuard.show(ModalShowType.LoginPwd);
      return;
    }
    deepGuard.show(ModalShowType.Login);
  }, []);

  useEffect(() => {
    const login = deepGuard.on(EventKeys.LOGIN, (data) => {
      console.log('登录成功----', data);
      const userInfo = { ...data.data };
      const { uid, accessToken } = userInfo;
      accessToken && window.localStorage.setItem('authorization', accessToken);
      uid && window.localStorage.setItem('u-id', uid);
      homeDispatch({
        field: 'userId',
        value: uid,
      });
      navigate('/');
    });

    return () => {
      deepGuard.remove(login);
    };
  }, []);

  return (
    <>
      <div id="authing-guard-container" className="relative w-48">
        <div className="loginContent">
          <DeepLoginPage />
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full h-10 bg-white text-center flex justify-center items-center">
        <img src={BeianLogo} alt="备案" className="w-4 h-4 mr-2" />
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802043273"
          rel="noreferrer"
          target="_blank"
        >
          京公网安备11010802043273
        </a>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          rel="noreferrer"
          target="_blank"
          className="ml-4"
        >
          京ICP备2022009790号-5
        </a>
      </div>
    </>
  );
};

export default Login;
