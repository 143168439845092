import { IconCheck, IconMessage, IconPencil, IconTrash, IconX } from '@tabler/icons-react';
import {
  DragEvent,
  KeyboardEvent,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Session } from '../../../types/chat';

import HomeContext from '../../../pages/home/home.context';

import SidebarActionButton from '../../Buttons/SidebarActionButton';
import ChatbarContext from '../../Chatbar/Chatbar.context';

interface Props {
  session: Session;
}

export const ConversationComponent = ({ session }: Props) => {
  const {
    state: { selectedSession, messageIsStreaming },
    handleSelectSession,
  } = useContext(HomeContext);

  const { handleDeleteSession, handleRenameSession } = useContext(ChatbarContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState<any>('');

  const handleEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      selectedSession && handleRename(selectedSession);
    }
  };

  const handleDragStart = (e: DragEvent<HTMLButtonElement>, session: Session) => {
    if (e.dataTransfer) {
      e.dataTransfer.setData('session', JSON.stringify(session));
    }
  };

  const handleRename = async (session: Session) => {
    if (renameValue.trim().length > 0) {
      handleRenameSession(renameValue, session);
      setRenameValue('');
      setIsRenaming(false);
    }
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (isDeleting) {
      handleDeleteSession(session);
    } else if (isRenaming) {
      handleRename(session);
    }
    setIsDeleting(false);
    setIsRenaming(false);
  };

  const handleCancel: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsDeleting(false);
    setIsRenaming(false);
  };

  const handleOpenRenameModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsRenaming(true);
    selectedSession && setRenameValue(selectedSession?.sessionTitle);
  };
  const handleOpenDeleteModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
  };

  useEffect(() => {
    if (isRenaming) {
      setIsDeleting(false);
    } else if (isDeleting) {
      setIsRenaming(false);
    }
  }, [isRenaming, isDeleting]);

  return (
    <div className="relative flex items-center">
      {isRenaming && selectedSession?.sessionId === session.sessionId ? (
        <div className="flex w-full items-center gap-3 rounded-lg bg-[#343541]/90 p-3">
          <IconMessage size={18} />
          <input
            className="mr-12 flex-1 overflow-hidden overflow-ellipsis border-neutral-400 bg-transparent text-left text-[12.5px] leading-3 text-white outline-none focus:border-neutral-100"
            type="text"
            value={renameValue}
            onChange={(e) => setRenameValue(e.target.value)}
            onKeyDown={handleEnterDown}
            autoFocus
          />
        </div>
      ) : (
        <button
          className={`flex w-full cursor-pointer items-center gap-3 rounded-lg p-3 text-sm transition-colors duration-200 hover:bg-[#343541]/90 ${
            messageIsStreaming ? 'disabled:cursor-not-allowed' : ''
          } ${selectedSession?.sessionId === session.sessionId ? 'bg-[#343541]/90' : ''}`}
          onClick={() => handleSelectSession(session)}
          disabled={messageIsStreaming}
          draggable="true"
          onDragStart={(e) => handleDragStart(e, session)}
        >
          <IconMessage size={18} />
          <div
            className={`relative max-h-5 flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left text-[12.5px] leading-3 ${
              selectedSession?.sessionId === session.sessionId ? 'pr-12' : 'pr-1'
            }`}
          >
            {session.sessionTitle}
          </div>
        </button>
      )}

      {(isDeleting || isRenaming) && selectedSession?.sessionId === session.sessionId && (
        <div className="absolute right-1 z-10 flex text-gray-300">
          <SidebarActionButton handleClick={handleConfirm}>
            <IconCheck size={18} />
          </SidebarActionButton>
          <SidebarActionButton handleClick={handleCancel}>
            <IconX size={18} />
          </SidebarActionButton>
        </div>
      )}

      {selectedSession?.sessionId === session.sessionId && !isDeleting && !isRenaming && (
        <div className="absolute right-1 z-10 flex text-gray-300">
          <SidebarActionButton handleClick={handleOpenRenameModal}>
            <IconPencil size={18} />
          </SidebarActionButton>
          <SidebarActionButton handleClick={handleOpenDeleteModal}>
            <IconTrash size={18} />
          </SidebarActionButton>
        </div>
      )}
    </div>
  );
};
